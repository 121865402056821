import React from 'react';
import { Navigate } from 'react-router-dom';
import NoAccess from '../components/common/NoAccess';
import { components } from './components';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import InteractiveRoute from './InteractiveRoute';
import PreviewRoute from './PreviewRoute';
import TableRoute from './TableRoute';

const appRoutes = ({ routesToRender }) => {
    const user = JSON.parse(sessionStorage.getItem('login-Data'));
    const { accessToken } = user?.data || {};
    const profileName = user?.data?.userDetails?.role?.profile_name;
    const isLoggedIn = !!accessToken;

    const subRoutes = {
        RESOURCE_MANAGEMENT: [
            {
                name: 'SUBJECT',
                path: 'subject',
            },
            {
                name: 'TOPIC',
                path: 'topic',
            },
            {
                name: 'RESOURCE',
                path: 'list',
            },
            {
                name: 'QUESTION_BANK',
                path: 'question-bank',
            },
        ],
        LESSON_PLANNER: [
            {
                name: 'SYLLABUS',
                path: 'syllabus',
            },
            {
                name: 'ASSIGN_SYLLABUS',
                path: 'assign-syllabus',
            },
            {
                name: 'BOOKS',
                path: 'books',
            },
            {
                name: 'CHAPTERS',
                path: 'chapters',
            },
            {
                name: 'LESSON_PLAN',
                path: 'lesson-plan',
            },
            {
                name: 'LESSON_PLAN_REPORT',
                path: 'lesson-plan-report',
            },
        ],
        REPORT_LIST: [
            {
                name: 'REPORT_LIST',
                path: 'reports',
            },
        ],
        STUDENT_ATTENDANCE: [
            {
                name: 'STUDENT_ATTENDANCE',
                path: 'attendance/list',
            },
        ],
        ASSESSMENT: [
            {
                name: 'ASSESSMENT_TYPES',
                path: 'type',
            },
            {
                name: 'ASSESSMENTS',
                path: 'list',
            },
        ],
    };

    const accessDataByPermissionKey = routesToRender.reduce((r, a) => {
        const temp = { ...r };
        temp[a.permission_name] = a;
        a.sub_permission.forEach((item) => {
            temp[item.permission_name] = item;
        });
        return temp;
    }, Object.create(null));

    const routeIndex = (mainRoute) => {
        const allSubRoutes = subRoutes[mainRoute];
        let routeIndexPath = '';
        allSubRoutes?.every((i) => {
            if (accessDataByPermissionKey[i.name] && accessDataByPermissionKey[i.name].view) {
                routeIndexPath = i.path;
                return false;
            }
            return true;
        });
        return routeIndexPath;
    };

    const getLessonPlanRouteElement = () => {
        if (profileName === 'SCHOOL') return <components.schoolLessonPlan />;
        if (profileName === 'TEACHER') return <components.teacherLessonPlan />;
        if (profileName === 'ADMIN') return <components.lessonPlan />;
        return null;
    };

    const getAttendanceRouteElement = () => {
        if (profileName === 'SCHOOL') return <components.schoolAttendance />;
        if (profileName === 'BRANCH') return <components.branchAttendance />;
        if (profileName === 'TEACHER') return <components.teacherAttendance />;

        return null;
    };

    return [
        {
            path: '/sign-in',
            element: (
                <PublicRoute isLoggedIn={isLoggedIn}>
                    <components.signIn />
                </PublicRoute>
            ),
        },
        {
            path: '/forget-password',
            element: (
                <PublicRoute isLoggedIn={isLoggedIn}>
                    <components.forgetPassword />
                </PublicRoute>
            ),
        },
        {
            path: '/resetpassword',
            element: (
                <PublicRoute isLoggedIn={isLoggedIn}>
                    <components.resetPassword />
                </PublicRoute>
            ),
        },
        {
            path: '/',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    {accessDataByPermissionKey.DASHBOARD && accessDataByPermissionKey.DASHBOARD?.view ? (
                        <components.dashboard routeAccessData={accessDataByPermissionKey.DASHBOARD} />
                    ) : (
                        <NoAccess />
                    )}
                </PrivateRoute>
            ),
        },
        {
            path: '/access',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    {accessDataByPermissionKey.ACCESS_MANAGEMENT &&
                    accessDataByPermissionKey.ACCESS_MANAGEMENT?.view ? (
                        <components.accessIndex routeAccessData={accessDataByPermissionKey.ACCESS_MANAGEMENT} />
                    ) : (
                        <NoAccess />
                    )}
                </PrivateRoute>
            ),
            children: [
                {
                    index: true,
                    element: <components.accessList />,
                },
                {
                    path: 'new',
                    element: <components.accessForm />,
                },
                {
                    path: 'edit/:id',
                    element: <components.accessForm />,
                },
                {
                    path: 'view/:id',
                    element: <components.accessForm />,
                },
            ],
        },
        // *Added by Anuja
        {
            path: '/reports',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    {accessDataByPermissionKey.REPORT_LIST && accessDataByPermissionKey.REPORT_LIST?.view ? (
                        <components.accessIndex routeAccessData={accessDataByPermissionKey.REPORT_LIST} />
                    ) : (
                        <NoAccess />
                    )}
                </PrivateRoute>
            ),
            children: [
                {
                    index: true,
                    element: <components.reportsList />,
                },
                {
                    path: 'add',
                    element: <components.addReport />,
                },
                {
                    path: 'edit/:day',
                    element: <components.addReport />,
                },
            ],
        },
        {
            path: '/school',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    {accessDataByPermissionKey.SCHOOL_MANAGEMENT &&
                    accessDataByPermissionKey.SCHOOL_MANAGEMENT?.view ? (
                        <components.schoolIndex routeAccessData={accessDataByPermissionKey.SCHOOL_MANAGEMENT} />
                    ) : (
                        <NoAccess />
                    )}
                </PrivateRoute>
            ),
            children: [
                {
                    index: true,
                    element: <Navigate to="list" />,
                },
                {
                    path: 'list',
                    element: (
                        <components.schoolList
                            routeAccessData={routesToRender.find(
                                (item) => item.permission_name === 'SCHOOL_MANAGEMENT'
                            )}
                        />
                    ),
                },
                {
                    path: 'add',
                    element: <components.schoolAddIndex />,
                    children: [
                        {
                            path: 'new',
                            element: (
                                <components.schoolAdd
                                    routeAccessData={routesToRender.find(
                                        (item) => item.permission_name === 'SCHOOL_MANAGEMENT'
                                    )}
                                />
                            ),
                        },
                        {
                            path: ':schoolId/branch/new',
                            element: (
                                <components.schoolAddBranch
                                    routeAccessData={routesToRender.find(
                                        (item) => item.permission_name === 'SCHOOL_MANAGEMENT'
                                    )}
                                />
                            ),
                        },
                        {
                            path: ':schoolId/user/new/:userType',
                            element: (
                                <components.schoolAddUser
                                    routeAccessData={routesToRender.find(
                                        (item) => item.permission_name === 'SCHOOL_MANAGEMENT'
                                    )}
                                />
                            ),
                        },
                        {
                            path: ':schoolId/branch/:branchId/user/new/:userType',
                            element: (
                                <components.schoolAddUser
                                    routeAccessData={routesToRender.find(
                                        (item) => item.permission_name === 'SCHOOL_MANAGEMENT'
                                    )}
                                />
                            ),
                        },
                    ],
                },
                {
                    path: 'view/:schoolId',
                    element: <components.schoolViewIndex />,
                    children: [
                        {
                            path: 'basic-info',
                            element: (
                                <components.schoolViewBasicInfo
                                    routeAccessData={routesToRender.find(
                                        (item) => item.permission_name === 'SCHOOL_MANAGEMENT'
                                    )}
                                />
                            ),
                        },
                        {
                            path: 'branch',
                            element: (
                                <components.schoolViewBranches
                                    routeAccessData={routesToRender.find(
                                        (item) => item.permission_name === 'SCHOOL_MANAGEMENT'
                                    )}
                                />
                            ),
                        },
                        {
                            path: 'user',
                            element: (
                                <components.schoolViewSchoolUsers
                                    routeAccessData={routesToRender.find(
                                        (item) => item.permission_name === 'SCHOOL_MANAGEMENT'
                                    )}
                                />
                            ),
                        },
                    ],
                },
                {
                    path: 'view/:schoolId/user/view/:userId',
                    element: <components.schoolAddUser />,
                },
                {
                    path: 'view/:schoolId/branch/view/:branchId',
                    element: <components.branchViewIndex />,
                    children: [
                        {
                            path: 'basic-info',
                            element: (
                                <components.branchViewBasicInfo
                                    routeAccessData={routesToRender.find(
                                        (item) => item.permission_name === 'SCHOOL_MANAGEMENT'
                                    )}
                                />
                            ),
                        },
                        {
                            path: 'teacher',
                            element: <components.schoolTeacherIndex />,
                            children: [
                                // {
                                // 	index: true,
                                // 	element: <Navigate to="list" />,
                                // },
                                {
                                    // path: 'list',
                                    index: true,
                                    element: <components.branchViewTeacher />,
                                },
                                {
                                    path: 'add/:schoolId/:branchId',
                                    element: <components.teacherForm />,
                                },
                                {
                                    path: 'view/:teacherId/basic-info',
                                    element: <components.teacherViewBasicInfo />,
                                    children: [
                                        {
                                            path: 'basic-info',
                                            element: <components.teacherViewBasicInfo />,
                                        },
                                    ],
                                },
                                {
                                    path: 'edit/:schoolId/:branchId/:teacherId',
                                    element: <components.teacherForm />,
                                },
                            ],
                        },
                        {
                            path: 'student',
                            element: <components.schoolTeacherIndex />,
                            children: [
                                // {
                                // 	index: true,
                                // 	element: <Navigate to="list" />,
                                // },
                                {
                                    // path: 'list',
                                    index: true,
                                    element: <components.branchViewStudent />,
                                },
                                {
                                    path: 'add/:schoolId/:branchId',
                                    element: <components.studentForm />,
                                },
                                {
                                    path: 'view/:userId/basic-info',
                                    element: <components.studentViewBasicInfo />,
                                    children: [
                                        {
                                            path: 'basic-info',
                                            element: <components.studentViewBasicInfo />,
                                        },
                                    ],
                                },
                                {
                                    path: 'edit/:schoolId/:branchId/:userId',
                                    element: <components.studentForm />,
                                },
                            ],
                        },
                        // {
                        //     path: 'student',
                        //     element: <components.schoolTeacherIndex />,
                        //     children: [
                        //         {
                        //             index: true,
                        //             element: (
                        //                 <components.branchViewStudent
                        //                     routeAccessData={routesToRender.find(
                        //                         (item) => item.permission_name === 'SCHOOL_MANAGEMENT'
                        //                     )}
                        //                 />
                        //             ),
                        //         },
                        //         {
                        //             path: 'add/:schoolId/:branchId',
                        //             element: <components.studentForm />,
                        //         },
                        //         {
                        //             path: 'edit/:userId',
                        //             element: <components.studentForm />,
                        //         },
                        //         {
                        //             path: 'view/:userId',
                        //             element: <components.studentViewIndex />,
                        //             children: [
                        //                 {
                        //                     path: 'basic-info',
                        //                     element: <components.studentViewBasicInfo />,
                        //                 },
                        //                 {
                        //                     path: 'attendance',
                        //                     element: <components.studentViewAttendance />,
                        //                 },
                        //                 // {
                        //                 //     path: 'assignments',
                        //                 //     element: <components.studentViewAssignments />,
                        //                 // },
                        //                 {
                        //                     path: 'communication',
                        //                     element: <components.studentViewCommunication />,
                        //                 },
                        //                 // {
                        //                 //     path: 'reportcard',
                        //                 //     element: <components.studentViewReportCard />,
                        //                 // },
                        //                 {
                        //                     path: 'remarks',
                        //                     element: <components.studentViewRemarks />,
                        //                 },
                        //             ],
                        //         },
                        //     ],
                        // },
                        {
                            path: 'other-user',
                            element: <components.schoolTeacherIndex />,
                            children: [
                                // {
                                // 	index: true,
                                // 	element: <Navigate to="list" />
                                // },
                                {
                                    // index: 'list',
                                    index: true,
                                    element: (
                                        <components.branchViewOtherUser
                                            routeAccessData={routesToRender.find(
                                                (item) => item.permission_name === 'SCHOOL_MANAGEMENT'
                                            )}
                                        />
                                    ),
                                },
                                {
                                    path: 'add/:schoolId/branch/:branchId/user/new/:userType',
                                    element: (
                                        <components.schoolAddUser
                                            routeAccessData={routesToRender.find(
                                                (item) => item.permission_name === 'SCHOOL_MANAGEMENT'
                                            )}
                                        />
                                    ),
                                },
                                {
                                    path: 'edit/:userId',
                                    element: <components.schoolAddUser />,
                                },
                                {
                                    path: 'view/:userId',
                                    element: <components.schoolAddUser />,
                                },
                            ],
                        },
                        {
                            path: 'user/:userId',
                            element: (
                                <components.branchViewOtherUser
                                    routeAccessData={routesToRender.find(
                                        (item) => item.permission_name === 'SCHOOL_MANAGEMENT'
                                    )}
                                />
                            ),
                        },
                    ],
                },
                {
                    path: 'edit/:schoolId',
                    element: <components.schoolAddIndex />,
                    children: [
                        {
                            index: true,
                            element: (
                                <components.schoolAdd
                                    routeAccessData={routesToRender.find(
                                        (item) => item.permission_name === 'SCHOOL_MANAGEMENT'
                                    )}
                                />
                            ),
                        },
                        {
                            path: 'branch/edit/:branchId',
                            element: (
                                <components.schoolAddBranch
                                    routeAccessData={routesToRender.find(
                                        (item) => item.permission_name === 'SCHOOL_MANAGEMENT'
                                    )}
                                />
                            ),
                        },
                        {
                            path: 'user/edit/:userId',
                            element: (
                                <components.schoolAddUser
                                    routeAccessData={routesToRender.find(
                                        (item) => item.permission_name === 'SCHOOL_MANAGEMENT'
                                    )}
                                />
                            ),
                        },
                    ],
                },
            ],
        },
        {
            path: '/teacher',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    {accessDataByPermissionKey.TEACHER_MANAGEMENT &&
                    accessDataByPermissionKey.TEACHER_MANAGEMENT?.view ? (
                        <components.teacherIndex routeAccessData={accessDataByPermissionKey.TEACHER_MANAGEMENT} />
                    ) : (
                        <NoAccess />
                    )}
                </PrivateRoute>
            ),
            children: [
                {
                    index: true,
                    element: <Navigate to="list" />,
                },
                {
                    path: 'list',
                    element: <components.teacherList />,
                },
                {
                    path: 'list/:schoolId',
                    element: <components.teacherList />,
                },
                {
                    path: 'list/:schoolId/:branchId',
                    element: <components.teacherList />,
                },
                {
                    path: 'add',
                    element: <components.teacherForm />,
                },
                {
                    path: 'add/:schoolId/:branchId',
                    element: <components.teacherForm />,
                },
                {
                    path: 'edit/:schoolId/:branchId/:teacherId',
                    element: <components.teacherForm />,
                },
                {
                    path: 'view/:teacherId',
                    element: <components.teacherViewIndex />,
                    children: [
                        {
                            path: 'basic-info',
                            element: <components.teacherViewBasicInfo />,
                        },
                        {
                            path: 'attendance',
                            element: <components.teacherViewAttendance />,
                        },
                        {
                            path: 'assignment',
                            element: <components.teacherViewAssignments />,
                        },
                        {
                            path: 'communication',
                            element: <components.teacherViewCommunication />,
                        },
                        {
                            path: 'assigned-class',
                            element: <components.teacherViewAssignedClass />,
                        },
                        {
                            path: 'remarks',
                            element: <components.teacherViewRemarks />,
                        },
                    ],
                },
                // *Added by Anuja
                {
                    path: 'change-password',
                    element: <components.changePassword />,
                },
                // ----
            ],
        },
        {
            path: '/student',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    {accessDataByPermissionKey.STUDENT_MANAGEMENT &&
                    accessDataByPermissionKey.STUDENT_MANAGEMENT?.view ? (
                        <components.studentIndex routeAccessData={accessDataByPermissionKey.STUDENT_MANAGEMENT} />
                    ) : (
                        <NoAccess />
                    )}
                </PrivateRoute>
            ),
            children: [
                {
                    index: true,
                    element: <Navigate to="list" />,
                },
                {
                    path: 'list',
                    element: <components.studentList />,
                },
                {
                    path: 'add',
                    element: <components.studentForm />,
                },
                {
                    path: 'add/:schoolId/:branchId',
                    element: <components.studentForm />,
                },
                {
                    path: 'edit/:userId',
                    element: <components.studentForm />,
                },
                {
                    path: 'view/:userId',
                    element: (
                        <components.studentViewIndex routeAccessData={accessDataByPermissionKey.STUDENT_MANAGEMENT} />
                    ),
                    children: [
                        {
                            path: 'basic-info',
                            element: <components.studentViewBasicInfo />,
                        },
                        {
                            path: 'attendance',
                            element: <components.studentViewAttendance />,
                        },
                        // {
                        //     path: 'assignments',
                        //     element: <components.studentViewAssignments />,
                        // },
                        {
                            path: 'communication',
                            element: <components.studentViewCommunication />,
                        },
                        // {
                        //     path: 'reportcard',
                        //     element: <components.studentViewReportCard />,
                        // },
                        {
                            path: 'remarks',
                            element: <components.studentViewRemarks />,
                        },
                    ],
                },
                // *Added by Anuja
                {
                    path: 'change-password',
                    element: <components.changePassword />,
                },
                // ----
            ],
        },
        {
            path: '/attendance',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    {accessDataByPermissionKey.STUDENT_ATTENDANCE &&
                    accessDataByPermissionKey.STUDENT_ATTENDANCE?.view ? (
                        <components.attendanceIndex routeAccessData={accessDataByPermissionKey.STUDENT_ATTENDANCE} />
                    ) : (
                        <NoAccess />
                    )}
                </PrivateRoute>
            ),
            children: [
                {
                    index: true,
                    element: <Navigate to={routeIndex('STUDENT_ATTENDANCE')} />,
                },
                {
                    path: 'list',
                    element: getAttendanceRouteElement(),
                },
            ],
        },
        {
            path: '/users',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    {accessDataByPermissionKey.USER_MANAGEMENT && accessDataByPermissionKey.USER_MANAGEMENT?.view ? (
                        <components.userIndex routeAccessData={accessDataByPermissionKey.USER_MANAGEMENT} />
                    ) : (
                        <NoAccess />
                    )}
                </PrivateRoute>
            ),
            children: [
                {
                    index: true,
                    element: <Navigate to="list" />,
                },
                {
                    path: 'list',
                    element: <components.userList />,
                },
                {
                    path: 'list/:userType',
                    element: <components.userList />,
                },
                {
                    path: 'list/:userType/:schoolId',
                    element: <components.userList />,
                },
                {
                    path: 'list/:userType/:schoolId/:branchId',
                    element: <components.userList />,
                },
                {
                    path: 'add/new/:userType',
                    element: <components.schoolAddUser />,
                },
                {
                    path: 'edit/:userId',
                    element: <components.schoolAddUser />,
                },
                {
                    path: 'view/:userId',
                    element: <components.schoolAddUser />,
                },
                {
                    path: 'edit/:schoolId/:branchId/:userId',
                    element: <components.schoolAddUser />,
                },
                {
                    path: 'view/:schoolId/:branchId/:userId',
                    element: <components.schoolAddUser />,
                },
            ],
        },
        {
            path: '/settings',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    {accessDataByPermissionKey.GENERAL_SETTINGS && accessDataByPermissionKey.GENERAL_SETTINGS?.view ? (
                        <components.settingsIndex routeAccessData={accessDataByPermissionKey.GENERAL_SETTINGS} />
                    ) : (
                        <NoAccess />
                    )}
                </PrivateRoute>
            ),
            children: [
                {
                    index: true,
                    element: <Navigate to="view" />,
                },
                {
                    path: 'view',
                    element: <components.settingsView />,
                },
                {
                    path: 'edit',
                    element: <components.settingsEdit />,
                },
                {
                    path: 'change-password',
                    element: <components.changePassword />,
                },
            ],
        },
        {
            path: '/resource',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    {accessDataByPermissionKey.RESOURCE_MANAGEMENT &&
                    accessDataByPermissionKey.RESOURCE_MANAGEMENT?.view ? (
                        <components.resourceIndex routeAccessData={accessDataByPermissionKey.RESOURCE_MANAGEMENT} />
                    ) : (
                        <NoAccess />
                    )}
                </PrivateRoute>
            ),
            children: [
                {
                    index: true,
                    element: <Navigate to={routeIndex('RESOURCE_MANAGEMENT')} />,
                },
                {
                    path: 'list',
                    element: <components.resourceView />,
                },
                {
                    path: 'subject',
                    element: <components.resourceViewSubject />,
                },
                {
                    path: 'topic',
                    element: <components.resourceViewTopic />,
                },
                {
                    path: 'question-bank',
                    element: <components.resourceViewQuestionBank />,
                },
                {
                    path: 'practice-resource',
                    element: <components.workSheetIndex />,
                },
                {
                    path: 'practice-video',
                    element: <components.resourceViewPracticeVideo />,
                },
                {
                    path: 'all-resources',
                    element: <components.allResources />,
                },
            ],
        },
        // Added be Anuja (to be changed once dynamic)
        {
            path: '/lesson-planner',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    {accessDataByPermissionKey.LESSON_PLANNER && accessDataByPermissionKey.LESSON_PLANNER?.view ? (
                        <components.lessonPlannerIndex routeAccessData={accessDataByPermissionKey.LESSON_PLANNER} />
                    ) : (
                        <NoAccess />
                    )}
                </PrivateRoute>
            ),
            children: [
                {
                    index: true,
                    element: <Navigate to={routeIndex('LESSON_PLANNER')} />,
                },
                {
                    path: 'syllabus',
                    element: <components.syllabusList />,
                },
                {
                    path: 'assign-syllabus',
                    element: <components.assignSyllabus />,
                },
                {
                    path: 'books',
                    element: <components.books />,
                },
                {
                    path: 'chapters',
                    element: <components.chapters />,
                },
                {
                    path: 'lesson-plan',
                    element: getLessonPlanRouteElement(),
                },
                {
                    path: 'lesson-plan-report',
                    element: <components.lessonPlanReport />,
                },
            ],
        },
        {
            path: '/assessment',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    {accessDataByPermissionKey.ASSESSMENT && accessDataByPermissionKey.ASSESSMENT?.view ? (
                        <components.assessmentIndex routeAccessData={accessDataByPermissionKey.ASSESSMENT} />
                    ) : (
                        <NoAccess />
                    )}
                </PrivateRoute>
            ),
            children: [
                // {
                //     index: true,
                //     element: <Navigate to="type" />,
                // },
                {
                    index: true,
                    element: <Navigate to={routeIndex('ASSESSMENT')} />,
                },
                {
                    path: 'type',
                    element: <components.assessmentType />,
                },
                {
                    path: 'list',
                    element: <components.assessments />,
                },
                {
                    path: 'assessment-report',
                    element: <components.assessmentReport />,
                },
            ],
        },
        {
            path: '/report/assessment/:id',
            element: (
                <TableRoute isLoggedIn={isLoggedIn} profile={profileName}>
                    <components.reportViewAssessment />
                </TableRoute>
            ),
        },
        {
            path: '/report/student/:id',
            element: (
                <TableRoute isLoggedIn={isLoggedIn} profile={profileName}>
                    <components.reportViewStudentAssessment />
                </TableRoute>
            ),
        },
        {
            path: '/assign-class',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    {accessDataByPermissionKey.ASSIGN_CLASS && accessDataByPermissionKey.ASSIGN_CLASS?.view ? (
                        <components.listAssignedClass routeAccessData={accessDataByPermissionKey.ASSIGN_CLASS || {}} />
                    ) : (
                        <NoAccess />
                    )}
                </PrivateRoute>
            ),
        },
        {
            path: '/timetable',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    {accessDataByPermissionKey.TIMETABLE && accessDataByPermissionKey.TIMETABLE?.view ? (
                        <components.timetableIndex routeAccessData={accessDataByPermissionKey.TIMETABLE || {}} />
                    ) : (
                        <NoAccess />
                    )}
                </PrivateRoute>
            ),
            children: [
                {
                    path: 'create',
                    element: <components.timetable routeAccessData={accessDataByPermissionKey.TIMETABLE || {}} />,
                },
            ],
        },
        {
            path: '/leaves',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    {accessDataByPermissionKey.LEAVES && accessDataByPermissionKey.LEAVES?.view ? (
                        <components.leavesIndex routeAccessData={accessDataByPermissionKey.LEAVES || {}} />
                    ) : (
                        <NoAccess />
                    )}
                </PrivateRoute>
            ),
            children: [
                {
                    index: true,
                    element: <Navigate to="list" />,
                },
                {
                    path: 'list',
                    element: <components.leaveslist />,
                },
            ],
        },
        {
            path: '/communicate',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    {accessDataByPermissionKey.COMMUNICATE && accessDataByPermissionKey.COMMUNICATE?.view ? (
                        <components.communicationIndex routeAccessData={accessDataByPermissionKey.COMMUNICATE || {}} />
                    ) : (
                        <NoAccess />
                    )}
                </PrivateRoute>
            ),
            children: [
                {
                    index: true,
                    element: <Navigate to="message" />,
                },
                {
                    path: 'message',
                    element: <components.communicationMessage />,
                },
                {
                    path: 'inbox',
                    element: <components.communicationInbox />,
                },
                {
                    path: 'sent-items',
                    element: <components.communicationSendItems />,
                },
                {
                    path: 'drafts',
                    element: <components.communicationDrafts />,
                },
                {
                    path: 'archives',
                    element: <components.communicationArchives />,
                },
                {
                    path: 'trash',
                    element: <components.communicationTrash />,
                },
                {
                    path: ':mailType/view/:mailId',
                    element: <components.communicationView />,
                },
            ],
        },
        {
            path: '/interactive',
            element: (
                <InteractiveRoute isLoggedIn={isLoggedIn} profile={profileName}>
                    <components.interactiveIndex />
                </InteractiveRoute>
            ),
        },
        {
            path: '/homepreview/preview/:interactiveType/:resourceId/:home',
            element: (
                <PreviewRoute>
                    <components.interactivePreviewIndex />
                </PreviewRoute>
            ),
        },
        {
            path: '/practices/:id/:practiceId',
            element: (
                <InteractiveRoute isLoggedIn={isLoggedIn} profile={profileName}>
                    <components.interactiveIndex />
                </InteractiveRoute>
            ),
        },
        {
            path: '/interactive/preview/:interactiveType/:resourceId',
            element: (
                <InteractiveRoute isLoggedIn={isLoggedIn}>
                    <components.interactivePreviewIndex />
                </InteractiveRoute>
            ),
        },
        {
            path: '/course-planer',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    {accessDataByPermissionKey.COURSE_PLANNER && accessDataByPermissionKey.COURSE_PLANNER?.view ? (
                        <components.coursePlanner routeAccessData={accessDataByPermissionKey.COURSE_PLANNER || {}} />
                    ) : (
                        <NoAccess />
                    )}
                </PrivateRoute>
            ),
        },
        {
            path: '/class-room',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    {accessDataByPermissionKey.CLASS_ROOM && accessDataByPermissionKey.CLASS_ROOM?.view ? (
                        <components.classRoom routeAccessData={accessDataByPermissionKey.CLASS_ROOM || {}} />
                    ) : (
                        <NoAccess />
                    )}
                </PrivateRoute>
            ),
        },
        {
            path: '/practices',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.practiceIndex />
                </PrivateRoute>
            ),
        },
        {
            path: '*',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.page404 />
                </PrivateRoute>
            ),
        },
    ];
};

export default appRoutes;
