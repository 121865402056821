import React from 'react';

const AnimatedCharacters = () => {
    const characters = [
        {
            image: 'bird-1.gif',
            style: 'character-type fly-BL2TR',
        },
        {
            image: 'bird-2.gif',
            style: 'character-type fly-BR2TL',
        },
        {
            image: 'honey-bee.gif',
            style: 'character-type fly-R2L',
        },
    ];
    const imgIndex = Math.floor(Math.random() * characters.length);
    return (
        <div className="animated-character">
            <img
                src={`/images/animation/${characters[imgIndex].image}`}
                alt=""
                className={`${characters[imgIndex].style}`}
            />
        </div>
    );
};

export default AnimatedCharacters;
