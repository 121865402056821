import apiGateWay from '../../../../serviceHandlers';
import { lessonPlannerEndPoints } from '../../../../config/serviceEndPoints';

// *Assign Syllabus
export const getAssignSyllabusList = (params) => {
    return apiGateWay.get(`${lessonPlannerEndPoints.assignSyllabus}`, { params });
};
export const addAssignSyllabus = (params, paths, payload) =>
    apiGateWay.post(lessonPlannerEndPoints.assignSyllabus, payload);

export const editAssignSyllabus = (params, paths, payload) =>
    apiGateWay.put(`${lessonPlannerEndPoints.assignSyllabus}/${paths?.assigned_syllabus_id}`, payload);

export const deleteAssignSyllabus = ({ assigned_syllabus_id }) => {
    return apiGateWay.delete(`${lessonPlannerEndPoints.assignSyllabus}/${assigned_syllabus_id}`);
};
