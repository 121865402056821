import { FALSY_VALUES } from '../utils/validations';
import LessonPlannerPermissionsList from '../components/lessonPlanner/LessonPlannerPermissionsList.json';

const permissionsList = [
    {
        profile_name: 'Admin',
        role_profile_id: 1,
        permissions: [
            {
                permission_name: 'DASHBOARD',
                display_name: 'Dashboard',
                permissionLogo: '/images/icons/dashboard.svg',
                isFilterActive: false,
                path: '/',
                sub_permission: [],
            },
            {
                permission_name: 'ACCESS_MANAGEMENT',
                display_name: 'Access Management',
                permissionLogo: '/images/icons/dashboard.svg',
                isFilterActive: false,
                path: '/access',
                sub_permission: [],
            },
            {
                permission_name: 'SCHOOL_MANAGEMENT',
                display_name: 'School Management',
                permissionLogo: '/images/icons/dashboard.svg',
                isFilterActive: false,
                path: '/school',
                sub_permission: [
                    {
                        permission_name: 'SCHOOL_LIST',
                        display_name: 'School List',
                        path: 'list',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'SCHOOL_DETAIL',
                        display_name: 'Basic Info',
                        path: 'view/:schoolId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'BRANCH_LIST',
                        display_name: 'Branches',
                        path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'SCHOOL_USERS',
                        display_name: 'School Users',
                        // path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'BRANCH_DETAIL',
                        display_name: 'Basic Info',
                        // path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'TEACHER',
                        display_name: 'Teacher',
                        // path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'STUDENT',
                        display_name: 'Student',
                        // path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'BRANCH_USERS',
                        display_name: 'Branch Users',
                        // path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                ],
            },
            // *Added by Anuja
            {
                permission_name: 'LESSON_PLANNER',
                display_name: 'Lesson Planner',
                sub_permission: [
                    {
                        permission_name: 'SYLLABUS',
                        display_name: 'Syllabus',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'ASSIGN_SYLLABUS',
                        display_name: 'Assign Syllabus',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'BOOKS',
                        display_name: 'Books',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'CHAPTERS',
                        display_name: 'Chapters',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'LESSON_PLAN',
                        display_name: 'Lesson Plan',
                        isFilterActive: false,
                    },
                    // {
                    //     permission_name: 'LESSON_PLAN_REPORT',
                    //     display_name: 'Lesson Plan Report',
                    //     isFilterActive: false,
                    // },
                ],
            },
            {
                permission_name: 'REPORT_LIST',
                display_name: 'Report List',
                sub_permission: [],
                isFilterActive: false,
                path: '/reports',
            },
            {
                permission_name: 'TEACHER_MANAGEMENT',
                display_name: 'Teacher Management',
                permissionLogo: '/images/icons/dashboard.svg',
                isFilterActive: false,
                path: '/teacher',
                sub_permission: [
                    {
                        permission_name: 'TEACHER_LIST',
                        display_name: 'Teacher List',
                        // path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'TEACHER_DETAIL',
                        display_name: 'Basic Info',
                        // path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'ATTENDANCE',
                        display_name: 'Attendance',
                        // path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'ASSIGNMENTS',
                        display_name: 'Assignments',
                        // path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'COMMUNICATION',
                        display_name: 'Communication',
                        // path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'ASSIGNED_CLASSES',
                        display_name: 'Assigned Classes',
                        // path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'REMARKS',
                        display_name: 'Remarks',
                        // path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                ],
            },
            {
                permission_name: 'STUDENT_MANAGEMENT',
                display_name: 'Student Management',
                permissionLogo: '/images/icons/dashboard.svg',
                isFilterActive: false,
                path: '/student',
                sub_permission: [
                    {
                        permission_name: 'STUDENT_LIST',
                        display_name: 'Student List',
                        // path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'STUDENT_DETAIL',
                        display_name: 'Basic Info',
                        // path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'ATTENDANCE',
                        display_name: 'Attendance',
                        // path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    // {
                    //     permission_name: 'ASSIGNMENTS',
                    //     display_name: 'Assignments',
                    //     // path: 'view/:schoolId/branch/view/:branchId',
                    //     isFilterActive: false,
                    // },
                    {
                        permission_name: 'COMMUNICATION',
                        display_name: 'Communication',
                        // path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    // {
                    //     permission_name: 'REPORT_CARD',
                    //     display_name: 'Report Card',
                    //     // path: 'view/:schoolId/branch/view/:branchId',
                    //     isFilterActive: false,
                    // },
                    {
                        permission_name: 'REMARKS',
                        display_name: 'Remarks',
                        // path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                ],
            },
            {
                permission_name: 'USER_MANAGEMENT',
                display_name: 'User Management',
                permissionLogo: '/images/icons/dashboard.svg',
                isFilterActive: false,
                path: '/users',
                sub_permission: [
                    {
                        permission_name: 'ADMIN_USERS',
                        display_name: 'Admin User',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'SCHOOL_USERS',
                        display_name: 'School User',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'BRANCH_USERS',
                        display_name: 'Branch User',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                ],
            },
            {
                permission_name: 'RESOURCE_MANAGEMENT',
                display_name: 'Resource Management',
                sub_permission: [
                    {
                        permission_name: 'SUBJECT',
                        display_name: 'Subject',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'TOPIC',
                        display_name: 'Topic',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'RESOURCE',
                        display_name: 'Interactive Resources',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'WORK_SHEET',
                        display_name: 'Practice Resource',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'QUESTION_BANK',
                        display_name: 'Question Bank',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'PRACTICE_VIDEO',
                        display_name: 'Practice Video',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'ALL_RESOURCE',
                        display_name: 'All Resource',
                        isFilterActive: false,
                    },
                ],
            },
            { ...LessonPlannerPermissionsList },
            {
                permission_name: 'ASSESSMENT',
                display_name: 'Assessment',
                //  path: 'view/:schoolId/branch/view/:branchId',
                isFilterActive: false,
                sub_permission: [
                    {
                        permission_name: 'ASSESSMENT_TYPES',
                        display_name: 'Assessment Types',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'ASSESSMENTS',
                        display_name: 'Assessments',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                ],
            },
            {
                permission_name: 'ASSIGN_CLASS',
                display_name: 'Assign Class',
                sub_permission: [],
            },
            {
                permission_name: 'COURSE_PLANNER',
                display_name: 'Course Planner',
                sub_permission: [],
            },
            {
                permission_name: 'ASSESSMENT',
                display_name: 'Assessment',
                sub_permission: [],
            },
            {
                permission_name: 'COMMUNICATE',
                display_name: 'Communicate',
                sub_permission: [],
            },
            {
                permission_name: 'TIMETABLE',
                display_name: 'Timetable',
                sub_permission: [],
            },
            {
                permission_name: 'MEETING',
                display_name: 'Meeting',
                sub_permission: [],
            },
            {
                permission_name: 'WORK_SHEET',
                display_name: 'Work Sheet',
                sub_permission: [],
            },
            {
                permission_name: 'LEAVES',
                display_name: 'Holidays',
                sub_permission: [],
            },
            {
                permission_name: 'GENERAL_SETTINGS',
                permissionLogo: '/images/icons/dashboard.svg',
                isFilterActive: false,
                path: '/settings',
                display_name: 'General Settings',
                sub_permission: [],
            },
        ],
    },
    {
        profile_name: 'School',
        role_profile_id: 2,
        permissions: [
            {
                permission_name: 'DASHBOARD',
                display_name: 'Dashboard',
                sub_permission: [],
                path: '/',
                isFilterActive: false,
            },
            // *Added by Anuja
            {
                permission_name: 'STUDENT_ATTENDANCE',
                display_name: 'Student Attendance',
                sub_permission: [
                    {
                        permission_name: 'STUDENT_ATTENDANCE',
                        display_name: 'Student Attendance',
                        isFilterActive: false,
                    },
                ],
            },
            {
                permission_name: 'SCHOOL_MANAGEMENT',
                display_name: 'School Management',
                sub_permission: [
                    {
                        permission_name: 'SCHOOL_LIST',
                        display_name: 'Basic Info',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'SCHOOL_DETAIL',
                        display_name: 'Basic Info',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'BRANCH_LIST',
                        display_name: 'Branches',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'SCHOOL_USERS',
                        display_name: 'School Users',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'BRANCH_DETAIL',
                        display_name: 'Basic Info',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'TEACHER',
                        display_name: 'Teacher',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'STUDENT',
                        display_name: 'Student',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'BRANCH_USERS',
                        display_name: 'Branch Users',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                ],
            },
            {
                permission_name: 'TEACHER_MANAGEMENT',
                display_name: 'Teacher Management',
                sub_permission: [
                    {
                        permission_name: 'TEACHER_LIST',
                        display_name: 'Teacher List',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'TEACHER_DETAIL',
                        display_name: 'Basic Info',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'ATTENDANCE',
                        display_name: 'Attendance',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'ASSIGNMENTS',
                        display_name: 'Assignments',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'COMMUNICATION',
                        display_name: 'Communication',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'ASSIGNED_CLASSES',
                        display_name: 'Assigned Classes',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'REMARKS',
                        display_name: 'Remarks',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                ],
            },
            {
                permission_name: 'STUDENT_MANAGEMENT',
                display_name: 'Student Management',
                //  path: 'view/:schoolId/branch/view/:branchId',
                isFilterActive: false,
                sub_permission: [
                    {
                        permission_name: 'STUDENT_LIST',
                        display_name: 'Student List',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'STUDENT_DETAIL',
                        display_name: 'Basic Info',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'ATTENDANCE',
                        display_name: 'Attendance',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    // {
                    //     permission_name: 'ASSIGNMENTS',
                    //     display_name: 'Assignments',
                    //     //  path: 'view/:schoolId/branch/view/:branchId',
                    //     isFilterActive: false,
                    // },
                    {
                        permission_name: 'COMMUNICATION',
                        display_name: 'Communication',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    // {
                    //     permission_name: 'REPORT_CARD',
                    //     display_name: 'Report Card',
                    //     //  path: 'view/:schoolId/branch/view/:branchId',
                    //     isFilterActive: false,
                    // },
                    {
                        permission_name: 'REMARKS',
                        display_name: 'Remarks',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                ],
            },
            {
                permission_name: 'USER_MANAGEMENT',
                display_name: 'User Management',
                //  path: 'view/:schoolId/branch/view/:branchId',
                isFilterActive: false,
                sub_permission: [
                    {
                        permission_name: 'SCHOOL_USERS',
                        display_name: 'School User',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'BRANCH_USERS',
                        display_name: 'Branch User',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                ],
            },
            {
                permission_name: 'ASSIGN_CLASS',
                display_name: 'Assign Class',
                //  path: 'view/:schoolId/branch/view/:branchId',
                isFilterActive: false,
                sub_permission: [],
            },
            {
                permission_name: 'COURSE_PLANNER',
                display_name: 'Course Planner',
                //  path: 'view/:schoolId/branch/view/:branchId',
                isFilterActive: false,
                sub_permission: [],
            },
            {
                permission_name: 'RESOURCE_MANAGEMENT',
                display_name: 'Resource Management',
                sub_permission: [
                    {
                        permission_name: 'RESOURCE',
                        display_name: 'Resource',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'QUESTION_BANK',
                        display_name: 'Question Bank',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'WORK_SHEET',
                        display_name: 'Practice Resource',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'PRACTICE_VIDEO',
                        display_name: 'Practice Video',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'ALL_RESOURCE',
                        display_name: 'All Resource',
                        isFilterActive: false,
                    },
                ],
            },
            // *Added by Anuja
            {
                permission_name: 'LESSON_PLANNER',
                display_name: 'Lesson Planner',
                sub_permission: [
                    {
                        permission_name: 'LESSON_PLAN',
                        display_name: 'Lesson Plan',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'LESSON_PLAN_REPORT',
                        display_name: 'Lesson Plan Report',
                        isFilterActive: false,
                    },
                ],
            },
            {
                permission_name: 'ASSESSMENT',
                display_name: 'Assessment',
                //  path: 'view/:schoolId/branch/view/:branchId',
                isFilterActive: false,
                sub_permission: [
                    {
                        permission_name: 'ASSESSMENT_TYPES',
                        display_name: 'Assessment Types',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'ASSESSMENTS',
                        display_name: 'Assessments',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                ],
            },
            {
                permission_name: 'COMMUNICATE',
                display_name: 'Communicate',
                //  path: 'view/:schoolId/branch/view/:branchId',
                isFilterActive: false,
                sub_permission: [],
            },
            {
                permission_name: 'TIMETABLE',
                display_name: 'Timetable',
                //  path: 'view/:schoolId/branch/view/:branchId',
                isFilterActive: false,
                sub_permission: [],
            },
            {
                permission_name: 'MEETING',
                display_name: 'Meeting',
                //  path: 'view/:schoolId/branch/view/:branchId',
                isFilterActive: false,
                sub_permission: [],
            },
            {
                permission_name: 'WORK_SHEET',
                display_name: 'Work Sheet',
                sub_permission: [],
            },
            {
                permission_name: 'LEAVES',
                display_name: 'Holidays',
                sub_permission: [],
            },
            {
                permission_name: 'GENERAL_SETTINGS',
                display_name: 'General Settings',
                //  path: 'view/:schoolId/branch/view/:branchId',
                isFilterActive: false,
                sub_permission: [],
            },
        ],
    },
    {
        profile_name: 'Branch',
        role_profile_id: 3,
        permissions: [
            {
                permission_name: 'DASHBOARD',
                display_name: 'Dashboard',
                //  path: 'view/:schoolId/branch/view/:branchId',
                isFilterActive: false,
                sub_permission: [],
            },
            // *Added by Anuja
            {
                permission_name: 'STUDENT_ATTENDANCE',
                display_name: 'Student Attendance',
                sub_permission: [
                    {
                        permission_name: 'STUDENT_ATTENDANCE',
                        display_name: 'Student Attendance',
                        isFilterActive: false,
                    },
                ],
            },
            {
                permission_name: 'SCHOOL_MANAGEMENT',
                display_name: 'School Management',
                //  path: 'view/:schoolId/branch/view/:branchId',
                isFilterActive: false,
                sub_permission: [
                    {
                        permission_name: 'BRANCH_DETAIL',
                        display_name: 'Basic Info',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'TEACHER',
                        display_name: 'Teacher',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'STUDENT',
                        display_name: 'Student',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'BRANCH_USERS',
                        display_name: 'Branch Users',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                ],
            },
            {
                permission_name: 'TEACHER_MANAGEMENT',
                display_name: 'Teacher Management',
                //  path: 'view/:schoolId/branch/view/:branchId',
                isFilterActive: false,
                sub_permission: [
                    {
                        permission_name: 'TEACHER_LIST',
                        display_name: 'Teacher List',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'TEACHER_DETAIL',
                        display_name: 'Basic Info',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'ATTENDANCE',
                        display_name: 'Attendance',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'ASSIGNMENTS',
                        display_name: 'Assignments',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'COMMUNICATION',
                        display_name: 'Communication',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'ASSIGNED_CLASSES',
                        display_name: 'Assigned Classes',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'REMARKS',
                        display_name: 'Remarks',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                ],
            },
            {
                permission_name: 'RESOURCE_MANAGEMENT',
                display_name: 'Resource Management',
                sub_permission: [
                    {
                        permission_name: 'RESOURCE',
                        display_name: 'Resource',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'QUESTION_BANK',
                        display_name: 'Question Bank',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'WORK_SHEET',
                        display_name: 'Practice Resource',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'PRACTICE_VIDEO',
                        display_name: 'Practice Video',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'ALL_RESOURCE',
                        display_name: 'All Resource',
                        isFilterActive: false,
                    },
                ],
            },
            {
                permission_name: 'STUDENT_MANAGEMENT',
                display_name: 'Student Management',
                //  path: 'view/:schoolId/branch/view/:branchId',
                isFilterActive: false,
                sub_permission: [
                    {
                        permission_name: 'STUDENT_LIST',
                        display_name: 'Student List',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'STUDENT_DETAIL',
                        display_name: 'Basic Info',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'ATTENDANCE',
                        display_name: 'Attendance',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    // {
                    //     permission_name: 'ASSIGNMENTS',
                    //     display_name: 'Assignments',
                    //     //  path: 'view/:schoolId/branch/view/:branchId',
                    //     isFilterActive: false,
                    // },
                    {
                        permission_name: 'COMMUNICATION',
                        display_name: 'Communication',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    // {
                    //     permission_name: 'REPORT_CARD',
                    //     display_name: 'Report Card',
                    //     //  path: 'view/:schoolId/branch/view/:branchId',
                    //     isFilterActive: false,
                    // },
                    {
                        permission_name: 'REMARKS',
                        display_name: 'Remarks',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                ],
            },
            {
                permission_name: 'USER_MANAGEMENT',
                display_name: 'User Management',
                //  path: 'view/:schoolId/branch/view/:branchId',
                isFilterActive: false,
                sub_permission: [
                    {
                        permission_name: 'BRANCH_USERS',
                        display_name: 'Branch User',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                ],
            },
            {
                permission_name: 'ASSIGN_CLASS',
                display_name: 'Assign Class',
                sub_permission: [],
            },
            {
                permission_name: 'COURSE_PLANNER',
                display_name: 'Course Planner',
                sub_permission: [],
            },
            // *Added by Anuja
            {
                permission_name: 'LESSON_PLANNER',
                display_name: 'Lesson Planner',
                sub_permission: [
                    {
                        permission_name: 'LESSON_PLAN_REPORT',
                        display_name: 'Lesson Plan Report',
                        isFilterActive: false,
                    },
                ],
            },
            {
                permission_name: 'ASSESSMENT',
                display_name: 'Assessment',
                sub_permission: [
                    {
                        permission_name: 'ASSESSMENT_TYPES',
                        display_name: 'Assessment Types',
                    },
                    {
                        permission_name: 'ASSESSMENTS',
                        display_name: 'Assessments',
                    },
                ],
            },
            {
                permission_name: 'RESOURCE_MANAGEMENT',
                display_name: 'Resource Management',
                sub_permission: [
                    {
                        permission_name: 'RESOURCE',
                        display_name: 'Resource',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'QUESTION_BANK',
                        display_name: 'Question Bank',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'PRACTICE_VIDEO',
                        display_name: 'Practice Video',
                        isFilterActive: false,
                    },
                ],
            },
            {
                permission_name: 'COMMUNICATE',
                display_name: 'Communicate',
                sub_permission: [],
            },
            {
                permission_name: 'TIMETABLE',
                display_name: 'Timetable',
                sub_permission: [],
            },
            {
                permission_name: 'MEETING',
                display_name: 'Meeting',
                sub_permission: [],
            },
            {
                permission_name: 'LEAVES',
                display_name: 'Holidays',
                sub_permission: [],
            },
            {
                permission_name: 'WORK_SHEET',
                display_name: 'Work Sheet',
                sub_permission: [],
            },
            {
                permission_name: 'GENERAL_SETTINGS',
                display_name: 'General Settings',
                //  path: 'view/:schoolId/branch/view/:branchId',
                isFilterActive: false,
                sub_permission: [],
            },
        ],
    },
    {
        profile_name: 'Teacher',
        role_profile_id: 4,
        permissions: [
            {
                permission_name: 'DASHBOARD',
                display_name: 'Dashboard',
                //  path: 'view/:schoolId/branch/view/:branchId',
                isFilterActive: false,
                sub_permission: [],
            },
            {
                permission_name: 'TEACHER_MANAGEMENT',
                display_name: 'Teacher Management',
                //  path: 'view/:schoolId/branch/view/:branchId',
                isFilterActive: false,
                sub_permission: [
                    {
                        permission_name: 'TEACHER_LIST',
                        display_name: 'Teacher List',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'TEACHER_DETAIL',
                        display_name: 'Basic Info',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'ATTENDANCE',
                        display_name: 'Attendance',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'ASSIGNMENTS',
                        display_name: 'Assignments',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'COMMUNICATION',
                        display_name: 'Communication',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'ASSIGNED_CLASSES',
                        display_name: 'Assigned Classes',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'REMARKS',
                        display_name: 'Remarks',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                ],
            },
            {
                permission_name: 'STUDENT_MANAGEMENT',
                display_name: 'Student Management',
                //  path: 'view/:schoolId/branch/view/:branchId',
                isFilterActive: false,
                sub_permission: [
                    {
                        permission_name: 'STUDENT_LIST',
                        display_name: 'Student List',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'STUDENT_DETAIL',
                        display_name: 'Basic Info',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'ATTENDANCE',
                        display_name: 'Attendance',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },

                    // {
                    //     permission_name: 'ASSIGNMENTS',
                    //     display_name: 'Assignments',
                    //     //  path: 'view/:schoolId/branch/view/:branchId',
                    //     isFilterActive: false,
                    // },
                    {
                        permission_name: 'COMMUNICATION',
                        display_name: 'Communication',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    // {
                    //     permission_name: 'REPORT_CARD',
                    //     display_name: 'Report Card',
                    //     //  path: 'view/:schoolId/branch/view/:branchId',
                    //     isFilterActive: false,
                    // },
                    {
                        permission_name: 'REMARKS',
                        display_name: 'Remarks',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                ],
            },
            // *Added by Anuja
            {
                permission_name: 'STUDENT_ATTENDANCE',
                display_name: 'Student Attendance',
                sub_permission: [
                    {
                        permission_name: 'STUDENT_ATTENDANCE',
                        display_name: 'Student Attendance',
                        isFilterActive: false,
                    },
                ],
            },
            {
                permission_name: 'RESOURCE_MANAGEMENT',
                display_name: 'Resource Management',
                sub_permission: [
                    {
                        permission_name: 'RESOURCE',
                        display_name: 'Resource',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'QUESTION_BANK',
                        display_name: 'Question Bank',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'WORK_SHEET',
                        display_name: 'Practice Resource',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'PRACTICE_VIDEO',
                        display_name: 'Practice Video',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'ALL_RESOURCE',
                        display_name: 'All Resource',
                        isFilterActive: false,
                    },
                ],
            },
            // *Added by Anuja
            {
                permission_name: 'LESSON_PLANNER',
                display_name: 'Lesson Planner',
                sub_permission: [
                    {
                        permission_name: 'LESSON_PLAN',
                        display_name: 'Lesson Plan',
                        isFilterActive: false,
                    },
                ],
            },
            {
                permission_name: 'REPORT_LIST',
                display_name: 'Report List',
                sub_permission: [],
                isFilterActive: false,
                path: '/reports',
            },
            {
                permission_name: 'COURSE_PLANNER',
                display_name: 'Course Planner',
                sub_permission: [],
            },
            {
                permission_name: 'ASSESSMENT',
                display_name: 'Assessment',
                sub_permission: [
                    {
                        permission_name: 'ASSESSMENT_TYPES',
                        display_name: 'Assessment Types',
                    },
                    {
                        permission_name: 'ASSESSMENTS',
                        display_name: 'Assessments',
                    },
                    {
                        permission_name: 'ASSESSMENT_REPORT',
                        display_name: 'Assessment Report',
                    },
                ],
            },
            {
                permission_name: 'COMMUNICATE',
                display_name: 'Communicate',
                sub_permission: [],
            },
            {
                permission_name: 'MEETING',
                display_name: 'Meeting',
                sub_permission: [],
            },
            {
                permission_name: 'LEAVES',
                display_name: 'Holidays',
                sub_permission: [],
            },
            {
                permission_name: 'CLASS_ROOM',
                display_name: 'Class Room',
                sub_permission: [],
            },
            {
                permission_name: 'WORK_SHEET',
                display_name: 'Work Sheet',
                sub_permission: [],
            },
        ],
    },
    {
        profile_name: 'Student',
        role_profile_id: 5,
        permissions: [
            {
                permission_name: 'DASHBOARD',
                display_name: 'Dashboard',
                //  path: 'view/:schoolId/branch/view/:branchId',
                isFilterActive: false,
                sub_permission: [],
            },
            {
                permission_name: 'STUDENT_MANAGEMENT',
                display_name: 'Student Management',
                //  path: 'view/:schoolId/branch/view/:branchId',
                isFilterActive: false,
                sub_permission: [
                    {
                        permission_name: 'STUDENT_DETAIL',
                        display_name: 'Basic Info',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    {
                        permission_name: 'ATTENDANCE',
                        display_name: 'Attendance',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    // {
                    //     permission_name: 'ASSIGNMENTS',
                    //     display_name: 'Assignments',
                    //     //  path: 'view/:schoolId/branch/view/:branchId',
                    //     isFilterActive: false,
                    // },
                    {
                        permission_name: 'COMMUNICATION',
                        display_name: 'Communication',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                    // {
                    //     permission_name: 'REPORT_CARD',
                    //     display_name: 'Report Card',
                    //     //  path: 'view/:schoolId/branch/view/:branchId',
                    //     isFilterActive: false,
                    // },
                    {
                        permission_name: 'REMARKS',
                        display_name: 'Remarks',
                        //  path: 'view/:schoolId/branch/view/:branchId',
                        isFilterActive: false,
                    },
                ],
            },
            {
                permission_name: 'COMMUNICATE',
                display_name: 'Communicate',
                child_permissions: [],
            },
            {
                permission_name: 'MEETING',
                display_name: 'Meeting',
                child_permissions: [],
            },
            {
                permission_name: 'CLASS_ROOM',
                display_name: 'Class Room',
                sub_permission: [],
            },
            {
                permission_name: 'LEAVES',
                display_name: 'Holidays',
                sub_permission: [],
            },
            {
                permission_name: 'PRACTICE',
                display_name: 'Practice',
                sub_permission: [],
            },
        ],
    },
];

const renderRoutes = (parameters) => {
    const { isLoggedIn, profile_name, rolePermissions } = parameters;
    if (FALSY_VALUES.includes(profile_name) || !isLoggedIn) {
        return [];
    }

    const userPermissionList = permissionsList.find(
        (permission) => permission.profile_name.toLowerCase() === profile_name.toLowerCase()
    );

    const updatedUserPermissionList = rolePermissions.map((rp) => {
        const permissionData =
            userPermissionList.permissions.find((item) => item.permission_name === rp.permission_name) || {};
        return {
            ...rp,
            ...permissionData,
            sub_permission: permissionData.sub_permission
                ? permissionData.sub_permission.map((sp) => {
                      const allowedActionForSubPermission =
                          rp.sub_permission.find((item) => item.permission_name === sp.permission_name) || {};
                      return {
                          ...sp,
                          ...(Object.keys(allowedActionForSubPermission).length
                              ? allowedActionForSubPermission
                              : { view: false, create: false, edit: false, delete: false, is_active: false }),
                      };
                  })
                : [],
        };
    });

    return updatedUserPermissionList;
};

export default renderRoutes;
