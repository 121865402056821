import * as commonTypes from '../../../common/constants';
import * as service from './services';
import { request } from '../../../../utils/utils';

// *Syllabus action-types
const GET_SYLLABUS_LIST_REQUEST = 'GET_SYLLABUS_LIST_REQUEST';
const GET_SYLLABUS_LIST_SUCCESS = 'GET_SYLLABUS_LIST_SUCCESS';
const GET_SYLLABUS_LIST_FAILURE = 'GET_SYLLABUS_LIST_FAILURE';

const GET_SYLLABUS_REQUEST = 'GET_SYLLABUS_REQUEST';
const GET_SYLLABUS_SUCCESS = 'GET_SYLLABUS_SUCCESS';
const GET_SYLLABUS_FAILURE = 'GET_SYLLABUS_FAILURE';

const POST_SYLLABUS_REQUEST = 'POST_SYLLABUS_REQUEST';
const POST_SYLLABUS_SUCCESS = 'POST_SYLLABUS_SUCCESS';
const POST_SYLLABUS_FAILURE = 'POST_SYLLABUS_FAILURE';
const POST_SYLLABUS_RESET = 'POST_SYLLABUS_RESET';

const EDIT_SYLLABUS_REQUEST = 'EDIT_SYLLABUS_REQUEST';
const EDIT_SYLLABUS_SUCCESS = 'EDIT_SYLLABUS_SUCCESS';
const EDIT_SYLLABUS_FAILURE = 'EDIT_SYLLABUS_FAILURE';
const EDIT_SYLLABUS_RESET = 'EDIT_SYLLABUS_RESET';

const DELETE_SYLLABUS_REQUEST = 'DELETE_SYLLABUS_REQUEST';
const DELETE_SYLLABUS_SUCCESS = 'DELETE_SYLLABUS_SUCCESS';
const DELETE_SYLLABUS_FAILURE = 'DELETE_SYLLABUS_FAILURE';

// *Syllabus actions
export const getAllSyllabusList = () => async (dispatch) => {
    request({
        dispatch,
        api: service.getSyllabuList,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: GET_SYLLABUS_LIST_REQUEST }],
        successActions: [
            { type: GET_SYLLABUS_LIST_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_SYLLABUS_LIST_REQUEST },
        ],
        failureActions: [
            { type: GET_SYLLABUS_LIST_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_SYLLABUS_LIST_REQUEST },
        ],
    });
};

export const getSyllabuses = (params) => async (dispatch) => {
    request({
        dispatch,
        api: service.getLessonPlannerSyllabuses,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: GET_SYLLABUS_REQUEST }],
        successActions: [
            { type: GET_SYLLABUS_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_SYLLABUS_REQUEST },
        ],
        failureActions: [
            { type: GET_SYLLABUS_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_SYLLABUS_REQUEST },
        ],
    });
};

export const createSyllabus = (payload) => async (dispatch) =>
    request({
        dispatch,
        api: service.createLessonPlannerSyllabuses,
        payload,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: POST_SYLLABUS_REQUEST }],
        successActions: [
            { type: POST_SYLLABUS_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: POST_SYLLABUS_REQUEST },
            { type: POST_SYLLABUS_RESET, payload: null },
        ],
        failureActions: [
            { type: POST_SYLLABUS_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: POST_SYLLABUS_REQUEST },
            { type: POST_SYLLABUS_RESET, payload: null },
        ],
    });

export const editSyllabus = (paths, payload) => async (dispatch) =>
    request({
        dispatch,
        api: service.editLessonPlannerSyllabuses,
        payload,
        paths,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: EDIT_SYLLABUS_REQUEST }],
        successActions: [
            { type: EDIT_SYLLABUS_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: EDIT_SYLLABUS_REQUEST },
            { type: EDIT_SYLLABUS_RESET, payload: null },
        ],
        failureActions: [
            { type: EDIT_SYLLABUS_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: EDIT_SYLLABUS_REQUEST },
        ],
    });

export const deleteSyllabus = (params) => async (dispatch) => {
    request({
        dispatch,
        api: service.deleteLessonPlannerSyllabuses,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: DELETE_SYLLABUS_REQUEST }],
        successActions: [
            { type: DELETE_SYLLABUS_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: DELETE_SYLLABUS_REQUEST },
        ],
        failureActions: [
            { type: DELETE_SYLLABUS_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: DELETE_SYLLABUS_REQUEST },
        ],
    });
};

// *Syllabus reducer
const initialState = {
    getSyllabusResponse: {},
    createSyllabusResponse: {},
    deleteSyllabusResponse: {},
    editSyllabusResponse: {},
    getAllSyllabusResponse: {},
};

const lessonPlannerSyllabusReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SYLLABUS_LIST_SUCCESS:
            return {
                ...state,
                getAllSyllabusResponse: { success: true, ...action.payload?.data },
            };
        case GET_SYLLABUS_LIST_FAILURE:
            return {
                ...state,
                getAllSyllabusResponse: { success: false, ...action.payload?.data },
            };
        case GET_SYLLABUS_SUCCESS:
            return {
                ...state,
                getSyllabusResponse: { success: true, ...action.payload?.data },
            };
        case GET_SYLLABUS_FAILURE:
            return {
                ...state,
                getSyllabusResponse: { success: false, ...action.payload?.data },
            };
        case POST_SYLLABUS_SUCCESS:
            return {
                ...state,
                createSyllabusResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case POST_SYLLABUS_FAILURE:
            return {
                ...state,
                createSyllabusResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case POST_SYLLABUS_RESET:
            return {
                ...state,
                createSyllabusResponse: {},
            };
        case EDIT_SYLLABUS_SUCCESS:
            return {
                ...state,
                editSyllabusResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case EDIT_SYLLABUS_FAILURE:
            return {
                ...state,
                editSyllabusResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case DELETE_SYLLABUS_SUCCESS:
            return {
                ...state,
                deleteSyllabusResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case DELETE_SYLLABUS_FAILURE:
            return {
                ...state,
                deleteSyllabusResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        default:
            return state;
    }
};

export default lessonPlannerSyllabusReducer;
