import apiGateWay from '../../../../serviceHandlers';
import { lessonPlannerEndPoints } from '../../../../config/serviceEndPoints';

// *Books
export const getSpecificLessonPlannerBooks = (params) => {
    return apiGateWay.get(`${lessonPlannerEndPoints.books}/list`, { params });
};
export const getSpecificLessonPlannerBooksBySchool = (params) => {
    return apiGateWay.get(`${lessonPlannerEndPoints.books}/list/by-school`, { params });
};
export const getLessonPlannerBooks = (params) => {
    return apiGateWay.get(`${lessonPlannerEndPoints.books}`, { params });
};
export const createLessonPlannerBooks = (params, paths, payload) =>
    apiGateWay.post(lessonPlannerEndPoints.books, payload);

export const editLessonPlannerBooks = (params, paths, payload) =>
    apiGateWay.put(`${lessonPlannerEndPoints.books}/${paths?.book_id}`, payload);

export const deleteLessonPlannerBooks = ({ book_id }) => {
    return apiGateWay.delete(`${lessonPlannerEndPoints.books}/${book_id}`);
};
