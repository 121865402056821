import * as commonTypes from '../../../../common/constants';
import * as service from './services';
import { request } from '../../../../../utils/utils';

// *Lesson Plan action-types
const GET_ASSIGNED_LESSON_PLAN_LIST_REQUEST = 'GET_ASSIGNED_LESSON_PLAN_LIST_REQUEST';
const GET_ASSIGNED_LESSON_PLAN_LIST_SUCCESS = 'GET_ASSIGNED_LESSON_PLAN_LIST_SUCCESS';
const GET_ASSIGNED_LESSON_PLAN_LIST_FAILURE = 'GET_ASSIGNED_LESSON_PLAN_LIST_FAILURE';

const GET_UNASSIGNED_LESSON_PLAN_LIST_REQUEST = 'GET_UNASSIGNED_LESSON_PLAN_LIST_REQUEST';
const GET_UNASSIGNED_LESSON_PLAN_LIST_SUCCESS = 'GET_UNASSIGNED_LESSON_PLAN_LIST_SUCCESS';
const GET_UNASSIGNED_LESSON_PLAN_LIST_FAILURE = 'GET_UNASSIGNED_LESSON_PLAN_LIST_FAILURE';

const ASSIGNE_LESSON_PLAN_REQUEST = 'ASSIGNE_LESSON_PLAN_REQUEST';
const ASSIGNE_LESSON_PLAN_SUCCESS = 'ASSIGNE_LESSON_PLAN_SUCCESS';
const ASSIGNE_LESSON_PLAN_FAILURE = 'ASSIGNE_LESSON_PLAN_FAILURE';
const ASSIGNE_LESSON_PLAN_RESET = 'ASSIGNE_LESSON_PLAN_RESET';

const EDIT_ASSIGNED_LESSON_PLAN_REQUEST = 'EDIT_ASSIGNED_LESSON_PLAN_REQUEST';
const EDIT_ASSIGNED_LESSON_PLAN_SUCCESS = 'EDIT_ASSIGNED_LESSON_PLAN_SUCCESS';
const EDIT_ASSIGNED_LESSON_PLAN_FAILURE = 'EDIT_ASSIGNED_LESSON_PLAN_FAILURE';
const EDIT_ASSIGNED_LESSON_PLAN_RESET = 'EDIT_ASSIGNED_LESSON_PLAN_RESET';

// *Lesson Plan actions
export const getAllAssignedLessonPlanList = (params) => async (dispatch) => {
    request({
        dispatch,
        api: service.getLessonPlanAssignedList,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: GET_ASSIGNED_LESSON_PLAN_LIST_REQUEST }],
        successActions: [
            { type: GET_ASSIGNED_LESSON_PLAN_LIST_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_ASSIGNED_LESSON_PLAN_LIST_REQUEST },
        ],
        failureActions: [
            { type: GET_ASSIGNED_LESSON_PLAN_LIST_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_ASSIGNED_LESSON_PLAN_LIST_REQUEST },
        ],
    });
};

export const getLessonPlanChaptersUnAssignedList = (params) => async (dispatch) => {
    request({
        dispatch,
        api: service.getLessonPlanChaptersUnAssignedList,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: GET_UNASSIGNED_LESSON_PLAN_LIST_REQUEST }],
        successActions: [
            { type: GET_UNASSIGNED_LESSON_PLAN_LIST_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_UNASSIGNED_LESSON_PLAN_LIST_REQUEST },
        ],
        failureActions: [
            { type: GET_UNASSIGNED_LESSON_PLAN_LIST_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_UNASSIGNED_LESSON_PLAN_LIST_REQUEST },
        ],
    });
};

export const postAssignTeacher = (payload) => async (dispatch) =>
    request({
        dispatch,
        api: service.assignTeacher,
        payload,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: ASSIGNE_LESSON_PLAN_REQUEST }],
        successActions: [
            { type: ASSIGNE_LESSON_PLAN_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: ASSIGNE_LESSON_PLAN_REQUEST },
            { type: ASSIGNE_LESSON_PLAN_RESET, payload: null },
        ],
        failureActions: [
            { type: ASSIGNE_LESSON_PLAN_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: ASSIGNE_LESSON_PLAN_REQUEST },
        ],
    });

export const editLessonPlanAssignedTeacher = (paths, payload) => async (dispatch) =>
    request({
        dispatch,
        api: service.editAssignedTeacher,
        paths,
        payload,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: EDIT_ASSIGNED_LESSON_PLAN_REQUEST }],
        successActions: [
            { type: EDIT_ASSIGNED_LESSON_PLAN_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: EDIT_ASSIGNED_LESSON_PLAN_REQUEST },
            { type: EDIT_ASSIGNED_LESSON_PLAN_RESET, payload: null },
        ],
        failureActions: [
            { type: EDIT_ASSIGNED_LESSON_PLAN_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: EDIT_ASSIGNED_LESSON_PLAN_REQUEST },
        ],
    });

// *Lesson Plan reducer
const initialState = {
    editAssignedTeacherResponse: {},
    getAllAssignedLessonPlanResponse: {},
    getUnAssignedChaptersListRes: {},
    postAssignTeacherRes: {},
};

const SchoollessonPlanReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ASSIGNED_LESSON_PLAN_LIST_SUCCESS:
            return {
                ...state,
                getAllAssignedLessonPlanResponse: { success: true, ...action.payload?.data },
            };
        case GET_ASSIGNED_LESSON_PLAN_LIST_FAILURE:
            return {
                ...state,
                getAllAssignedLessonPlanResponse: { success: false, ...action.payload?.data },
            };
        case GET_UNASSIGNED_LESSON_PLAN_LIST_SUCCESS:
            return {
                ...state,
                getUnAssignedChaptersListRes: { success: true, ...action.payload?.data },
            };
        case GET_UNASSIGNED_LESSON_PLAN_LIST_FAILURE:
            return {
                ...state,
                getUnAssignedChaptersListRes: { success: false, ...action.payload?.data },
            };
        case ASSIGNE_LESSON_PLAN_SUCCESS:
            return {
                ...state,
                postAssignTeacherRes: {
                    success: true,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case ASSIGNE_LESSON_PLAN_FAILURE:
            return {
                ...state,
                postAssignTeacherRes: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case ASSIGNE_LESSON_PLAN_RESET:
            return {
                ...state,
                postAssignTeacherRes: {
                    ...state.postAssignTeacherRes,
                    message: '',
                },
            };
        case EDIT_ASSIGNED_LESSON_PLAN_SUCCESS:
            return {
                ...state,
                editAssignedTeacherResponse: {
                    success: true,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case EDIT_ASSIGNED_LESSON_PLAN_FAILURE:
            return {
                ...state,
                editAssignedTeacherResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case EDIT_ASSIGNED_LESSON_PLAN_RESET: {
            return {
                ...state,
                editAssignedTeacherResponse: {
                    ...state.editAssignedTeacherResponse,
                    message: '',
                },
            };
        }
        default:
            return state;
    }
};

export default SchoollessonPlanReducer;
