import * as commonTypes from '../../../common/constants';
import * as service from './services';
import { request } from '../../../../utils/utils';

// *Chapters action-types
const GET_CHAPTER_LIST_REQUEST = 'GET_CHAPTER_LIST_REQUEST';
const GET_CHAPTER_LIST_SUCCESS = 'GET_CHAPTER_LIST_SUCCESS';
const GET_CHAPTER_LIST_FAILURE = 'GET_CHAPTER_LIST_FAILURE';

const POST_CHAPTER_REQUEST = 'POST_CHAPTER_REQUEST';
const POST_CHAPTER_SUCCESS = 'POST_CHAPTER_SUCCESS';
const POST_CHAPTER_FAILURE = 'POST_CHAPTER_FAILURE';
const POST_CHAPTER_RESET = 'POST_CHAPTER_RESET';

const EDIT_CHAPTER_REQUEST = 'EDIT_CHAPTER_REQUEST';
const EDIT_CHAPTER_SUCCESS = 'EDIT_CHAPTER_SUCCESS';
const EDIT_CHAPTER_FAILURE = 'EDIT_CHAPTER_FAILURE';
const EDIT_CHAPTER_RESET = 'EDIT_CHAPTER_RESET';

const DELETE_CHAPTER_REQUEST = 'DELETE_CHAPTER_REQUEST';
const DELETE_CHAPTER_SUCCESS = 'DELETE_CHAPTER_SUCCESS';
const DELETE_CHAPTER_FAILURE = 'DELETE_CHAPTER_FAILURE';

// *Chapters actions
export const getAllChaptersList = (params) => async (dispatch) => {
    request({
        dispatch,
        api: service.getLessonPlannerChapters,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: GET_CHAPTER_LIST_REQUEST }],
        successActions: [
            { type: GET_CHAPTER_LIST_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_CHAPTER_LIST_REQUEST },
        ],
        failureActions: [
            { type: GET_CHAPTER_LIST_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_CHAPTER_LIST_REQUEST },
        ],
    });
};

export const createChapter = (payload) => async (dispatch) =>
    request({
        dispatch,
        api: service.createLessonPlannerChapter,
        payload,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: POST_CHAPTER_REQUEST }],
        successActions: [
            { type: POST_CHAPTER_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: POST_CHAPTER_REQUEST },
            { type: POST_CHAPTER_RESET, payload: null },
        ],
        failureActions: [
            { type: POST_CHAPTER_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: POST_CHAPTER_REQUEST },
            { type: POST_CHAPTER_RESET, payload: null },
        ],
    });

export const editChapter = (paths, payload) => async (dispatch) =>
    request({
        dispatch,
        api: service.editLessonPlannerChapter,
        payload,
        paths,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: EDIT_CHAPTER_REQUEST }],
        successActions: [
            { type: EDIT_CHAPTER_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: EDIT_CHAPTER_REQUEST },
            { type: EDIT_CHAPTER_RESET, payload: null },
        ],
        failureActions: [
            { type: EDIT_CHAPTER_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: EDIT_CHAPTER_REQUEST },
        ],
    });

export const deleteChapter = (params) => async (dispatch) => {
    request({
        dispatch,
        api: service.deleteLessonPlannerChapter,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: DELETE_CHAPTER_REQUEST }],
        successActions: [
            { type: DELETE_CHAPTER_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: DELETE_CHAPTER_REQUEST },
        ],
        failureActions: [
            { type: DELETE_CHAPTER_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: DELETE_CHAPTER_REQUEST },
        ],
    });
};

// *Chapter reducer
const initialState = {
    getChaptersResponse: {},
    createChapterResponse: {},
    deleteChapterResponse: {},
    editChapterResponse: {},
};

const lessonPlannerChaptersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CHAPTER_LIST_SUCCESS:
            return {
                ...state,
                getChaptersResponse: { success: true, ...action.payload?.data },
            };
        case GET_CHAPTER_LIST_FAILURE:
            return {
                ...state,
                getChaptersResponse: { success: false, ...action.payload?.data },
            };
        case POST_CHAPTER_SUCCESS:
            return {
                ...state,
                createChapterResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case POST_CHAPTER_FAILURE:
            return {
                ...state,
                createChapterResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case POST_CHAPTER_RESET:
            return {
                ...state,
                createChapterResponse: {},
            };
        case EDIT_CHAPTER_SUCCESS:
            return {
                ...state,
                editChapterResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case EDIT_CHAPTER_FAILURE:
            return {
                ...state,
                editChapterResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case DELETE_CHAPTER_SUCCESS:
            return {
                ...state,
                deleteChapterResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case DELETE_CHAPTER_FAILURE:
            return {
                ...state,
                deleteChapterResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        default:
            return state;
    }
};

export default lessonPlannerChaptersReducer;
