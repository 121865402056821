import * as commonTypes from '../../../common/constants';
import * as service from './services';
import { request } from '../../../../utils/utils';

// *Lesson Plan action-types
const GET_LESSON_PLAN_LIST_REQUEST = 'GET_LESSON_PLAN_LIST_REQUEST';
const GET_LESSON_PLAN_LIST_SUCCESS = 'GET_LESSON_PLAN_LIST_SUCCESS';
const GET_LESSON_PLAN_LIST_FAILURE = 'GET_LESSON_PLAN_LIST_FAILURE';

const EDIT_LESSON_PLAN_REQUEST = 'EDIT_LESSON_PLAN_REQUEST';
const EDIT_LESSON_PLAN_SUCCESS = 'EDIT_LESSON_PLAN_SUCCESS';
const EDIT_LESSON_PLAN_FAILURE = 'EDIT_LESSON_PLAN_FAILURE';
const EDIT_LESSON_PLAN_RESET = 'EDIT_LESSON_PLAN_RESET';

// *Lesson Plan actions
export const getAllLessonPlanList = (params) => async (dispatch) => {
    request({
        dispatch,
        api: service.getLessonPlanist,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: GET_LESSON_PLAN_LIST_REQUEST }],
        successActions: [
            { type: GET_LESSON_PLAN_LIST_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_LESSON_PLAN_LIST_REQUEST },
        ],
        failureActions: [
            { type: GET_LESSON_PLAN_LIST_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_LESSON_PLAN_LIST_REQUEST },
        ],
    });
};

export const editLessonPlan = (payload) => async (dispatch) =>
    request({
        dispatch,
        api: service.editLessonPlan,
        payload,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: EDIT_LESSON_PLAN_REQUEST }],
        successActions: [
            { type: EDIT_LESSON_PLAN_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: EDIT_LESSON_PLAN_REQUEST },
            { type: EDIT_LESSON_PLAN_RESET, payload: null },
        ],
        failureActions: [
            { type: EDIT_LESSON_PLAN_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: EDIT_LESSON_PLAN_REQUEST },
        ],
    });

// *Lesson Plan reducer
const initialState = {
    editLessonPlanResponse: {},
    getAllLessonPlanResponse: {},
};

const lessonPlanReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LESSON_PLAN_LIST_SUCCESS:
            return {
                ...state,
                getAllLessonPlanResponse: { success: true, ...action.payload?.data },
            };
        case GET_LESSON_PLAN_LIST_FAILURE:
            return {
                ...state,
                getAllLessonPlanResponse: { success: false, ...action.payload?.data },
            };
        case EDIT_LESSON_PLAN_SUCCESS:
            return {
                ...state,
                editLessonPlanResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case EDIT_LESSON_PLAN_FAILURE:
            return {
                ...state,
                editLessonPlanResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        default:
            return state;
    }
};

export default lessonPlanReducer;
