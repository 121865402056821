import * as commonTypes from '../../../common/constants';
import * as service from './services';
import { request } from '../../../../utils/utils';

// *Assign Syllabus action-types
const GET_ASSIGN_SYLLABUS_REQUEST = 'GET_ASSIGN_SYLLABUS_REQUEST';
const GET_ASSIGN_SYLLABUS_SUCCESS = 'GET_ASSIGN_SYLLABUS_SUCCESS';
const GET_ASSIGN_SYLLABUS_FAILURE = 'GET_ASSIGN_SYLLABUS_FAILURE';

const POST_ASSIGN_SYLLABUS_REQUEST = 'POST_ASSIGN_SYLLABUS_REQUEST';
const POST_ASSIGN_SYLLABUS_SUCCESS = 'POST_ASSIGN_SYLLABUS_SUCCESS';
const POST_ASSIGN_SYLLABUS_FAILURE = 'POST_ASSIGN_SYLLABUS_FAILURE';
const POST_ASSIGN_SYLLABUS_RESET = 'POST_ASSIGN_SYLLABUS_RESET';

const EDIT_ASSIGN_SYLLABUS_REQUEST = 'EDIT_ASSIGN_SYLLABUS_REQUEST';
const EDIT_ASSIGN_SYLLABUS_SUCCESS = 'EDIT_ASSIGN_SYLLABUS_SUCCESS';
const EDIT_ASSIGN_SYLLABUS_FAILURE = 'EDIT_ASSIGN_SYLLABUS_FAILURE';
const EDIT_ASSIGN_SYLLABUS_RESET = 'EDIT_ASSIGN_SYLLABUS_RESET';

const DELETE_ASSIGN_SYLLABUS_REQUEST = 'DELETE_ASSIGN_SYLLABUS_REQUEST';
const DELETE_ASSIGN_SYLLABUS_SUCCESS = 'DELETE_ASSIGN_SYLLABUS_SUCCESS';
const DELETE_ASSIGN_SYLLABUS_FAILURE = 'DELETE_ASSIGN_SYLLABUS_FAILURE';

// *Assign Syllabus actions
export const getAssignedSyllabusList = (params) => async (dispatch) => {
    request({
        dispatch,
        api: service.getAssignSyllabusList,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: GET_ASSIGN_SYLLABUS_REQUEST }],
        successActions: [
            { type: GET_ASSIGN_SYLLABUS_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_ASSIGN_SYLLABUS_REQUEST },
        ],
        failureActions: [
            { type: GET_ASSIGN_SYLLABUS_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_ASSIGN_SYLLABUS_REQUEST },
        ],
    });
};

export const addAssignedSyllabus = (payload) => async (dispatch) =>
    request({
        dispatch,
        api: service.addAssignSyllabus,
        payload,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: POST_ASSIGN_SYLLABUS_REQUEST }],
        successActions: [
            { type: POST_ASSIGN_SYLLABUS_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: POST_ASSIGN_SYLLABUS_REQUEST },
            { type: POST_ASSIGN_SYLLABUS_RESET, payload: null },
        ],
        failureActions: [
            { type: POST_ASSIGN_SYLLABUS_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: POST_ASSIGN_SYLLABUS_REQUEST },
            { type: POST_ASSIGN_SYLLABUS_RESET, payload: null },
        ],
    });

export const editAssignedSyllabus = (paths, payload) => async (dispatch) => {
    return request({
        dispatch,
        api: service.editAssignSyllabus,
        payload,
        paths,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: EDIT_ASSIGN_SYLLABUS_REQUEST }],
        successActions: [
            { type: EDIT_ASSIGN_SYLLABUS_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: EDIT_ASSIGN_SYLLABUS_REQUEST },
            { type: EDIT_ASSIGN_SYLLABUS_RESET, payload: null },
        ],
        failureActions: [
            { type: EDIT_ASSIGN_SYLLABUS_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: EDIT_ASSIGN_SYLLABUS_REQUEST },
        ],
    });
};

export const deleteAssignedSyllabus = (params) => async (dispatch) => {
    request({
        dispatch,
        api: service.deleteAssignSyllabus,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: DELETE_ASSIGN_SYLLABUS_REQUEST }],
        successActions: [
            { type: DELETE_ASSIGN_SYLLABUS_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: DELETE_ASSIGN_SYLLABUS_REQUEST },
        ],
        failureActions: [
            { type: DELETE_ASSIGN_SYLLABUS_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: DELETE_ASSIGN_SYLLABUS_REQUEST },
        ],
    });
};

// *Assign Syllabus reducer
const initialState = {
    getAssignedSyllabusListResponse: {},
    addAssignSyllabusResponse: {},
    deleteAssignSyllabusResponse: {},
    editAssignSyllabusResponse: {},
};

const lessonPlannerAssignSyllabusReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ASSIGN_SYLLABUS_SUCCESS:
            return {
                ...state,
                getAssignedSyllabusListResponse: { success: true, ...action.payload?.data },
            };
        case GET_ASSIGN_SYLLABUS_FAILURE:
            return {
                ...state,
                getAssignedSyllabusListResponse: { success: false, ...action.payload?.data },
            };
        case POST_ASSIGN_SYLLABUS_SUCCESS:
            return {
                ...state,
                addAssignSyllabusResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case POST_ASSIGN_SYLLABUS_FAILURE:
            return {
                ...state,
                addAssignSyllabusResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case POST_ASSIGN_SYLLABUS_RESET:
            return {
                ...state,
                addAssignSyllabusResponse: {},
            };
        case EDIT_ASSIGN_SYLLABUS_SUCCESS:
            return {
                ...state,
                editAssignSyllabusResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case EDIT_ASSIGN_SYLLABUS_FAILURE:
            return {
                ...state,
                editAssignSyllabusResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case DELETE_ASSIGN_SYLLABUS_SUCCESS:
            return {
                ...state,
                deleteAssignSyllabusResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case DELETE_ASSIGN_SYLLABUS_FAILURE:
            return {
                ...state,
                deleteAssignSyllabusResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        default:
            return state;
    }
};

export default lessonPlannerAssignSyllabusReducer;
