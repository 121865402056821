// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-wrap {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #00000042;
    left: 0;
    top: 0;
    z-index: 1;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

/*  Progress Bar Styles */

/*  Progress Bar Styles */

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

.animated-loader {
    width: 150px;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/loader.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,2BAA2B;IAC3B,OAAO;IACP,MAAM;IACN,UAAU;AACd;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,UAAU;IACV,kBAAkB;IAClB,8DAA8D;AAClE;;AAEA;IACI,sBAAsB;AAC1B;;AAEA,yBAAyB;;AAEzB,yBAAyB;;AAEzB;IACI;QACI,SAAS;QACT,UAAU;QACV,QAAQ;QACR,SAAS;QACT,UAAU;IACd;IACA;QACI,QAAQ;QACR,SAAS;QACT,WAAW;QACX,YAAY;QACZ,UAAU;IACd;AACJ;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".loader-wrap {\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    background-color: #00000042;\n    left: 0;\n    top: 0;\n    z-index: 1;\n}\n\n.lds-ripple {\n    display: inline-block;\n    position: relative;\n    width: 80px;\n    height: 80px;\n}\n\n.lds-ripple div {\n    position: absolute;\n    border: 4px solid #fff;\n    opacity: 1;\n    border-radius: 50%;\n    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;\n}\n\n.lds-ripple div:nth-child(2) {\n    animation-delay: -0.5s;\n}\n\n/*  Progress Bar Styles */\n\n/*  Progress Bar Styles */\n\n@keyframes lds-ripple {\n    0% {\n        top: 36px;\n        left: 36px;\n        width: 0;\n        height: 0;\n        opacity: 1;\n    }\n    100% {\n        top: 0px;\n        left: 0px;\n        width: 72px;\n        height: 72px;\n        opacity: 0;\n    }\n}\n\n.animated-loader {\n    width: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
