import * as commonTypes from '../common/constants';
import * as service from './services';
import { request } from '../../utils/utils';

// *Report List action-types
const GET_REPORT_LIST_REQUEST = 'GET_REPORT_LIST_REQUEST';
const GET_REPORT_LIST_SUCCESS = 'GET_REPORT_LIST_SUCCESS';
const GET_REPORT_LIST_FAILURE = 'GET_REPORT_LIST_FAILURE';
const GET_REPORT_LIST_RESET = 'GET_REPORT_LIST_RESET';

const ADD_REPORT_REQUEST = 'ADD_REPORT_REQUEST';
const ADD_REPORT_SUCCESS = 'ADD_REPORT_SUCCESS';
const ADD_REPORT_FAILURE = 'ADD_REPORT_FAILURE';
const ADD_REPORT_RESET = 'ADD_REPORT_RESET';

const DELETE_REPORT_REQUEST = 'DELETE_REPORT_REQUEST';
const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS';
const DELETE_REPORT_FAILURE = 'DELETE_REPORT_FAILURE';
const DELETE_REPORT_RESET = 'DELETE_REPORT_RESET';

// *Report List actions
export const getAllReportsList = (params) => async (dispatch) => {
    request({
        dispatch,
        api: service.getReportList,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: GET_REPORT_LIST_REQUEST }],
        successActions: [
            { type: GET_REPORT_LIST_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_REPORT_LIST_REQUEST },
        ],
        failureActions: [
            { type: GET_REPORT_LIST_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_REPORT_LIST_REQUEST },
        ],
    });
};

export const addEditReportList = (payload) => async (dispatch) =>
    request({
        dispatch,
        api: service.addReport,
        payload,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: ADD_REPORT_REQUEST }],
        successActions: [
            { type: ADD_REPORT_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: ADD_REPORT_REQUEST },
            { type: ADD_REPORT_RESET, payload: null },
        ],
        failureActions: [
            { type: ADD_REPORT_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: ADD_REPORT_REQUEST },
        ],
    });

export const deleteReport = (payload, fn) => async (dispatch) =>
    request({
        dispatch,
        api: service.deleteReport,
        payload,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: DELETE_REPORT_REQUEST }],
        successActions: [
            { type: DELETE_REPORT_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: DELETE_REPORT_REQUEST },
            { type: DELETE_REPORT_RESET, payload: null },
        ],
        failureActions: [
            { type: DELETE_REPORT_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: DELETE_REPORT_REQUEST },
        ],
        fn: (res) => res,
    });

export const resetReport = () => (dispatch) => dispatch({ type: 'ADD_REPORT_RESET' });
export const resetAllReports = () => (dispatch) => dispatch({ type: 'GET_REPORT_LIST_RESET' });

// *Report List reducer
const initialState = {
    addReportResponse: {},
    getAllReportsResponse: {},
    deleteReportResponse: {},
};

const reportsViewReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REPORT_LIST_SUCCESS:
            return {
                ...state,
                getAllReportsResponse: { success: true, ...action.payload?.data },
            };
        case GET_REPORT_LIST_FAILURE:
            return {
                ...state,
                getAllReportsResponse: { success: false, ...action.payload?.data },
            };
        case GET_REPORT_LIST_RESET:
            return {
                ...state,
                getAllReportsResponse: {},
            };
        case ADD_REPORT_SUCCESS:
            return {
                ...state,
                addReportResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case ADD_REPORT_FAILURE:
            return {
                ...state,
                addReportResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case ADD_REPORT_RESET:
            return {
                ...state,
                addReportResponse: {},
            };
        case DELETE_REPORT_SUCCESS:
            return {
                ...state,
                deleteReportResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case DELETE_REPORT_FAILURE:
            return {
                ...state,
                deleteReportResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case DELETE_REPORT_RESET:
            return {
                ...state,
                deleteReportResponse: { ...state.addReportResponse, message: '' },
            };

        default:
            return state;
    }
};

export default reportsViewReducer;
