export const userEndPoints = {
    loginUser: '/api/auth/login',
    forgetUser: '/api/auth/forget-password',
    checkUser: '/api/auth/checkToken',
    resetUser: '/api/auth/resetPassword',
    registerUser: '/api/auth/createUser',
};

export const accessEndpoints = {
    getRoleProfiles: '/api/access/roleProfiles',
    getRoles: '/api/access/roles',
    getPermissions: '/api/access/permissions',
    rolepermissions: '/api/access/rolepermissions',
    getPermissionsById: '/api/access/rolepermissions',
    validateRole: '/api/access/role/validate',
};

export const commonEndPoints = {
    allowedPermissions: '/api/access/allowedpermissions',
    breadcrumbData: '/api/settings/breadcrumb-data',
};

export const teacherEndPoints = {
    listTeacher: '/api/teacher',
    getTeacherById: '/api/teacher',
    getAssignedClass: '/api/teacher/assigned-class',
    createTeacher: '/api/teacher',
    updateTeacher: '/api/teacher',
    teacherBulkUpload: '/api/teacher/bulk-upload',
    createAssignedClass: '/api/teacher/assign-class',
    deleteAssignedClass: '/api/teacher/assign-class/remove',
    updateAssignedClass: '/api/teacher/assign-class',
    getBulkUploadLink: '/api/settings/bulksample',
    teacherAttendance: '/api/teacher/attendance/list',
    indivitualCommunication: '/api/communication/mail/individual',
};

export const schoolManagementEndPoints = {
    schoolList: '/api/school',
    getSchoolById: '/api/school',
    getBranchById: '/api/school',
    getSchoolBranches: '/api/school',
    createSchool: '/api/school',
    createBranch: '/api/school',
    updateSchool: '/api/school',
    updateBranch: '/api/school',
    deleteSchool: '/api/school',
    getAllSchool: '/api/school/all',
    getAllBranch: '/api/school',
    getAllTerms: '/api/school/terms',
};
export const studentManagementEndPoints = {
    student: '/api/student',
    remarks: '/api/remarks',
    getRollNo: '/api/student/rollno',
};

export const classDivSubjectsEndpoints = {
    listSubject: '/api/school/subjects',
    listclass: '/api/school/class',
    listdivision: '/api/school/division',
};

export const settingsEndPoints = {
    getProfile: '/api/settings/profile',
    updateProfile: '/api/settings/profile',
    resetPassword: '/api/settings/profile/reset-password',
};

export const schoolUserEndPoints = {
    userBaseUrl: '/api/user',
};

export const resourceEndpoints = {
    postAssignClass: '/api/resource/subjects/assign-class',
    getSubjects: '/api/resource/subjects',
    getTopics: '/api/resource/topics',
    getResources: '/api/resource/resources',
    getResourcesPublic: 'api/resource/resources/info',
    getAllTopics: '/api/resource/topics',
    getAllResourceTypes: '/api/resource/resource_types/all',
    getAllResourceSubTypes: '/api/resource/resource_subtypes',
    deleteSubject: '/api/resource/subjects',
    question: '/api/resource/question-bank',
    getPracticeVideos: '/api/practice/video',
    getPracticeVideosOtp: '/api/practice/video/generate-otp',
    getGlobalResource: '/api/resource/search',
    addtoHome: '/api/resource/add-to-home-banner',
    getHome: '/api/resource/home-banner',
    countQuestion: '/api/resource/question-bank/count-place',
    sortQuestion: '/api/resource/question-bank/sort-object',
    circleQuestion: '/api/resource/question-bank/circle-objects',
    colorQuestion: '/api/resource/question-bank/color-objects',
};

export const assessmentEndPoints = {
    getAssessmentType: '/api/assessment/type',
    createAssessmentType: '/api/assessment/type',
    updateAssessmentType: '/api/assessment/type',
    deleteAssessmentType: '/api/assessment/type',
    getAssessment: '/api/assessment',
    createAssessment: '/api/assessment',
    updateAssessment: '/api/assessment',
    deleteAssessment: '/api/assessment',
    updateAssessmentStatus: '/api/assessment/status',
    updateAssessmentPractice: '/api/assessment/add-to-practice',
    assessmentGrade: '/api/report/grades',
    assessmentReport: '/api/report/assessments',
    assessmentReportId: '/api/report/assessment',
    assessmentStudentReport: '/api/report/students',
    generateAssessmentReport: '/api/report/generate/assessments',
    generateStudentReport: '/api/report/generate/students',
};

export const assignClassEndPoints = {
    getAssignedClass: '/api/assign-class',
    getAllTeachers: '/api/teacher/all',
    createAssignedClass: '/api/assign-class',
    updateAssignedClass: '/api/assign-class',
    deleteAssignedClass: '/api/assign-class/remove',
};

export const holidayEndPoints = {
    holiday: '/api/holiday',
};

export const coursePlannerEndPoints = {
    coursePlanner: '/api/session-plan',
    resources: '/api/resource/resources/all',
    getTeachers: '/api/teacher/class/assigned-teachers',
    assessments: '/api/assessment/all',
    hasSessionPlan: '/api/session-plan/timetable-sessions',
};

export const zoomEndpoints = {
    generateSignature: '/api/zoom/generateSignature',
};

export const timetableEndpoints = {
    getSessionTypes: '/api/timetable/session-types',
    generateTtFile: '/api/timetable/generate-file',
    timeTableBase: '/api/timetable',
    updateSessionSubject: '/api/timetable/session/subject',
    createHoliday: '/api/holiday',
};

export const communicateEndpoints = {
    group: '/api/communication/group',
    mail: '/api/communication/mail',
};

export const interactive = {
    getSessionResources: '/api/interaction/session-resources',
    getSessionCustomResources: '/api/interaction/session-custom-resource',
    getSessionAssesments: '/api/interaction/session-assesments',
    getSession: '/api/interaction/session',
    getAttendance: '/api/interaction/session-attendance',
};

export const practice = {
    practiceDefault: '/api/practice/training',
    practiceTest: '/api/practice/training/assessments',
    practiceSubmit: '/api/practice/training/assessments/submit-result',
};
export const workSheetEndpoints = {
    getWorkSheet: '/api/practice',
};

export const dashboard = {
    getCount: '/api/dashboard/counts',
    getResourceTypeCount: '/api/dashboard/resource-type',
    getHolidays: '/api/dashboard/holidays',
    getStudentsCount: '/api/dashboard/students-count',
    getStudentAttendance: '/api/dashboard/attendance',
    getTodaySession: '/api/dashboard/today-sessions',
    getAssessment: '/api/dashboard/assesments',
};

// *Added by Anuja
export const lessonPlannerEndPoints = {
    syllabus: '/api/syllabus',
    books: '/api/book',
    assignSyllabus: '/api/assign-syllabus',
    chapters: '/api/chapter',
    lessonPlan: '/api/lesson/plan',
    teacherLessonPlan: 'api/lesson/teacher/plan',
    lessonPlanReport: '/api/lesson/plan-report',
};
export const attendance = {
    getList: '/api/attendance',
};
export const reportList = {
    getReports: '/api/report-view',
};
