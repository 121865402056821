import apiGateWay from '../../../../serviceHandlers';
import { lessonPlannerEndPoints } from '../../../../config/serviceEndPoints';
// *Chapters

export const getLessonPlannerChapters = (params) => {
    return apiGateWay.get(`${lessonPlannerEndPoints.chapters}`, { params });
};
export const createLessonPlannerChapter = (params, paths, payload) =>
    apiGateWay.post(lessonPlannerEndPoints.chapters, payload);

export const editLessonPlannerChapter = (params, paths, payload) =>
    apiGateWay.put(`${lessonPlannerEndPoints.chapters}/${paths.chapter_id}`, payload);

export const deleteLessonPlannerChapter = ({ chapter_id }) => {
    return apiGateWay.delete(`${lessonPlannerEndPoints.chapters}/${chapter_id}`);
};
