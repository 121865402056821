import React from 'react';

export const components = {
    page404: React.lazy(() => import('../components/common/Page404')),
    signIn: React.lazy(() => import('../components/auth/SignIn')),
    dashboard: React.lazy(() => import('../components/dashboard/Index')),
    schoolIndex: React.lazy(() => import('../components/schoolManagement/components/Index')),
    schoolList: React.lazy(() => import('../components/schoolManagement/components/school/ListSchool')),
    schoolAddIndex: React.lazy(() => import('../components/schoolManagement/components/SchoolAddIndex')),
    schoolAdd: React.lazy(() => import('../components/schoolManagement/components/school/addSchoolForm/AddSchool')),
    schoolAddBranch: React.lazy(() => import('../components/schoolManagement/components/school/AddSchoolBranch')),
    schoolAddUser: React.lazy(() => import('../components/schoolManagement/components/school/AddSchoolUser')),
    schoolViewIndex: React.lazy(() => import('../components/schoolManagement/components/school/ViewSchoolIndex')),
    branchViewIndex: React.lazy(() => import('../components/schoolManagement/components/branch/ViewBranchIndex')),
    schoolViewBasicInfo: React.lazy(() =>
        import('../components/schoolManagement/components/school/ViewSchoolBasicInfo')
    ),
    branchViewBasicInfo: React.lazy(() =>
        import('../components/schoolManagement/components/branch/ViewBranchBasicInfo')
    ),
    branchViewTeacher: React.lazy(() => import('../components/schoolManagement/components/branch/ViewBranchTeacher')),
    branchViewStudent: React.lazy(() => import('../components/schoolManagement/components/branch/ViewBranchStudent')),
    branchViewOtherUser: React.lazy(() =>
        import('../components/schoolManagement/components/branch/ViewBranchOtherUser')
    ),
    schoolViewBranches: React.lazy(() => import('../components/schoolManagement/components/school/ViewSchoolBranches')),
    schoolViewSchoolUsers: React.lazy(() => import('../components/schoolManagement/components/school/ViewSchoolUsers')),
    accessIndex: React.lazy(() => import('../components/accessManagement/Main')),
    accessMain: React.lazy(() => import('../components/accessManagement/Index')),
    accessList: React.lazy(() => import('../components/accessManagement/List')),
    accessForm: React.lazy(() => import('../components/accessManagement/Form')),
    teacherIndex: React.lazy(() => import('../components/teacherManagement/components/Index')),
    teacherList: React.lazy(() => import('../components/teacherManagement/components/List')),
    teacherForm: React.lazy(() => import('../components/teacherManagement/components/Form')),
    teacherViewIndex: React.lazy(() => import('../components/teacherManagement/components/ViewIndex')),
    teacherViewBasicInfo: React.lazy(() => import('../components/teacherManagement/components/ViewBasicInfo')),
    teacherViewAttendance: React.lazy(() => import('../components/teacherManagement/components/ViewAttendance')),
    teacherViewAssignments: React.lazy(() => import('../components/teacherManagement/components/ViewAssignments')),
    teacherViewCommunication: React.lazy(() => import('../components/teacherManagement/components/ViewCommunication')),
    teacherViewAssignedClass: React.lazy(() => import('../components/teacherManagement/components/ViewAssignedClass')),
    teacherViewRemarks: React.lazy(() => import('../components/teacherManagement/components/ViewRemarks')),

    // eslint-disable-next-line import/no-cycle
    schoolTeacherIndex: React.lazy(() => import('../components/schoolManagement/components/TeacherIndex')),

    studentIndex: React.lazy(() => import('../components/studentManagement/components/Index')),
    studentList: React.lazy(() => import('../components/studentManagement/components/StudentList')),
    studentViewIndex: React.lazy(() => import('../components/studentManagement/view/StudentIndex')),
    studentViewBasicInfo: React.lazy(() => import('../components/studentManagement/view/ViewStudentInfo')),
    studentViewAttendance: React.lazy(() => import('../components/studentManagement/view/ViewAttendance')),
    studentViewAssignments: React.lazy(() => import('../components/studentManagement/view/ViewAssignments')),
    studentViewCommunication: React.lazy(() => import('../components/studentManagement/view/ViewCommunication')),
    studentViewReportCard: React.lazy(() => import('../components/studentManagement/view/ViewRepostCard')),
    studentViewRemarks: React.lazy(() => import('../components/studentManagement/view/ViewRemarks')),
    studentForm: React.lazy(() => import('../components/studentManagement/pages/StudentForm')),
    userIndex: React.lazy(() => import('../components/userManagement/components/Index')),
    userList: React.lazy(() => import('../components/userManagement/components/UserList/Index')),
    settingsIndex: React.lazy(() => import('../components/generalSettings/components/Index')),
    settingsView: React.lazy(() => import('../components/generalSettings/components/View')),
    settingsEdit: React.lazy(() => import('../components/generalSettings/components/Edit')),
    changePassword: React.lazy(() => import('../components/generalSettings/components/ChangePassword')),

    resourceIndex: React.lazy(() => import('../components/resourceManagement/view/index')),
    resourceView: React.lazy(() => import('../components/resourceManagement/view/resources')),
    resourceViewSubject: React.lazy(() => import('../components/resourceManagement/view/subject')),
    resourceViewTopic: React.lazy(() => import('../components/resourceManagement/view/topic')),
    resourceViewQuestionBank: React.lazy(() => import('../components/resourceManagement/view/questionBank')),
    resourceViewPracticeVideo: React.lazy(() => import('../components/resourceManagement/view/Videos')),

    assessmentIndex: React.lazy(() => import('../components/assessment/view/Index')),
    assessmentType: React.lazy(() => import('../components/assessment/view/AssessmentType')),
    assessments: React.lazy(() => import('../components/assessment/view/Assessment')),

    listAssignedClass: React.lazy(() => import('../components/assignClass/pages/List')),

    timetableIndex: React.lazy(() => import('../components/timetable/Index')),
    timetable: React.lazy(() => import('../components/timetable/components/Index')),

    leavesIndex: React.lazy(() => import('../components/leaveManagement/components/index')),
    leaveslist: React.lazy(() => import('../components/leaveManagement/components/leaveList')),
    // Added be Anuja (to be changed once dynamic)
    lessonPlannerIndex: React.lazy(() => import('../components/lessonPlanner/index')),
    syllabusList: React.lazy(() => import('../components/lessonPlanner/subMenuViews/syllabus/index')),
    assignSyllabus: React.lazy(() => import('../components/lessonPlanner/subMenuViews/assignSyllabus/index')),
    books: React.lazy(() => import('../components/lessonPlanner/subMenuViews/books/index')),
    chapters: React.lazy(() => import('../components/lessonPlanner/subMenuViews/chapters/index')),
    lessonPlan: React.lazy(() => import('../components/lessonPlanner/subMenuViews/lessonPlan/index')),
    lessonPlanReport: React.lazy(() => import('../components/lessonPlanner/subMenuViews/lessonPlanReport/index')),
    schoolLessonPlan: React.lazy(() => import('../components/lessonPlanner/subMenuViews/lessonPlan/school/index')),
    teacherLessonPlan: React.lazy(() => import('../components/lessonPlanner/subMenuViews/lessonPlan/teacher/index')),

    communicationIndex: React.lazy(() => import('../components/communication/components/index')),
    communicationMessage: React.lazy(() => import('../components/communication/components/communicationMessage')),
    communicationInbox: React.lazy(() => import('../components/communication/components/communicationInbox')),
    communicationSendItems: React.lazy(() => import('../components/communication/components/communicationSendItems')),
    communicationDrafts: React.lazy(() => import('../components/communication/components/communicationDrafts')),
    communicationArchives: React.lazy(() => import('../components/communication/components/communicationArchives')),
    communicationTrash: React.lazy(() => import('../components/communication/components/communicationTrash')),
    communicationView: React.lazy(() => import('../components/communication/pages/mailView')),

    coursePlanner: React.lazy(() => import('../components/coursePlanner/view/SessionList')),

    classRoom: React.lazy(() => import('../components/classRoom/pages/classRoom')),

    interactiveIndex: React.lazy(() => import('../components/interactive/pages/index')),
    interactivePreviewIndex: React.lazy(() => import('../components/interactivePreview/Index')),

    practiceIndex: React.lazy(() => import('../components/practice/pages/Index')),
    workSheetIndex: React.lazy(() => import('../components/workSheet/pages/WorkSheet')),
    allResources: React.lazy(() => import('../components/resourceManagement/view/allResources')),
    assessmentReport: React.lazy(() => import('../components/assessment/view/AssessmentReport')),
    reportViewAssessment: React.lazy(() => import('../components/assessment/view/ReportViewAssessment')),
    reportViewStudentAssessment: React.lazy(() => import('../components/assessment/view/ReportViewStudent')),
    forgetPassword: React.lazy(() => import('../components/auth/ForgetPassword')),
    resetPassword: React.lazy(() => import('../components/auth/ResetPassword')),

    // *Added by Anuja
    attendanceIndex: React.lazy(() => import('../components/attendance/index')),
    teacherAttendance: React.lazy(() => import('../components/attendance/teacher/TeacherAttendance')),
    schoolAttendance: React.lazy(() => import('../components/attendance/school/SchoolAttendance')),
    branchAttendance: React.lazy(() => import('../components/attendance/branch/BranchAttendance')),
    reportsList: React.lazy(() => import('../components/reportList/ReportList')),
    addReport: React.lazy(() => import('../components/reportList/AddReport')),
};
