import apiGateWay from '../../../../../serviceHandlers';
import { lessonPlannerEndPoints } from '../../../../../config/serviceEndPoints';
// *Lesson Plan
export const getLessonPlanAssignedList = (params) => {
    return apiGateWay.get(`${lessonPlannerEndPoints.lessonPlan}/assigned-list`, { params });
};
export const getLessonPlanChaptersUnAssignedList = (params) => {
    return apiGateWay.get(`${lessonPlannerEndPoints.lessonPlan}/unassigned-chapter-list`, { params });
};
export const assignTeacher = (params, paths, payload) => {
    return apiGateWay.post(`${lessonPlannerEndPoints.lessonPlan}/assign-teacher`, payload);
};
export const editAssignedTeacher = (params, paths, payload) => {
    return apiGateWay.put(`${lessonPlannerEndPoints.lessonPlan}/assign-teacher/${paths.lesson_plan_id}`, payload);
};
