import apiGateWay from '../../../../serviceHandlers';
import { lessonPlannerEndPoints } from '../../../../config/serviceEndPoints';
// *Lesson Plan Report
export const getLessonPlanReportList = (params) => {
    return apiGateWay.get(`${lessonPlannerEndPoints.lessonPlanReport}`, { params });
};

export const downloadLessonPlanReport = (params) => {
 return apiGateWay.get(`${lessonPlannerEndPoints.lessonPlanReport}/download`, { params });
};
