import { combineReducers } from 'redux';
import lessonPlannerSyllabusReducer from './subMenuViews/syllabus/slice';
import lessonPlannerBooksReducer from './subMenuViews/books/slice';
import lessonPlannerAssignSyllabusReducer from './subMenuViews/assignSyllabus/slice';
import lessonPlannerChaptersReducer from './subMenuViews/chapters/slice';
import lessonPlanReducer from './subMenuViews/lessonPlan/slice';
import SchoollessonPlanReducer from './subMenuViews/lessonPlan/school/slice';
import SchoollessonPlanReportReducer from './subMenuViews/lessonPlanReport/slice';

const lessonPlannerReducer = combineReducers({
    syllabus: lessonPlannerSyllabusReducer,
    books: lessonPlannerBooksReducer,
    assignSyllabus: lessonPlannerAssignSyllabusReducer,
    chapters: lessonPlannerChaptersReducer,
    lessonPlans: lessonPlanReducer,
    schoolLessonPlan: SchoollessonPlanReducer,
    SchoolLessonPlanReport: SchoollessonPlanReportReducer,
});

export default lessonPlannerReducer;
