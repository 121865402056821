import React, { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppRoutes from './routes/Index';
import Loader from './components/common/Loader';
import ScrollToTop from './components/common/ScrollToTop';
import renderRoutes from './routes/routePermissionsConfig';
import LessonPlannerSidebarMenuTemp from './components/lessonPlanner/LessonPlannerSidebarMenuTemp.json';

const App = () => {
    const [routesToRender, setRoutesToRender] = useState([]);
    const loaderList = useSelector((state) => state.commonReducers.loaderList);
    const sideBarResponse = useSelector((state) => state.commonReducers.sideBarResponse);
    let sideBarMenus =
        sideBarResponse?.data?.data?.rolePermissions || sideBarResponse?.data?.data?.allowed_permissions || [];

    const user = JSON.parse(sessionStorage.getItem('login-Data'));
    const { userDetails, accessToken } = user?.data || {};
    const isLoggedIn = !!accessToken;
    const isTeacherAdminLogin = userDetails?.role?.role_profile_id === 1 || userDetails?.role?.role_profile_id === 4;

    // *TODO: Added by Anuja(remove once dynamic)
    if (isTeacherAdminLogin) {
        sideBarMenus = [
            ...sideBarMenus,
            {
                permission_name: 'REPORT_LIST',
                display_name: 'Report List',
                view: true,
                create: true,
                edit: true,
                delete: true,
                is_active: true,
                sub_permission: [],
            },
        ];
    }

    useEffect(() => {
        if (sideBarResponse.success) {
            setRoutesToRender(
                renderRoutes({
                    profile_name: userDetails?.role?.profile_name,
                    isLoggedIn,
                    rolePermissions: [...sideBarMenus, LessonPlannerSidebarMenuTemp],
                })
            );
        }
    }, [sideBarResponse]);

    return (
        <>
            <ToastContainer closeButton transition={Slide} icon theme="colored" hideProgressBar />
            {/* {loaderList.length ? <Loader /> : null} */}
            <Suspense fallback={<Loader />}>
                <Router>
                    <ScrollToTop />
                    <AppRoutes routesToRender={routesToRender} />
                </Router>
            </Suspense>
        </>
    );
};

export default App;
