/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actionType from './constants';
import BreadCrumb from './BreadCrumb';
import AnimatedCharacters from './AnimatedCharacters';
import AnimatedObjects from './AnimatedObjects';
import { getLatestMail } from '../communication/actions';

const Header = ({ interactive, report, hideinfo }) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const { getLatestMailResponse } = useSelector((state) => state.communicateReducers);
    const { isExpanded } = useSelector((state) => state.commonReducers.toggleSidebar);
    const currentLoginData = JSON.parse(sessionStorage.getItem('login-Data'));
    const profileData = JSON.parse(sessionStorage.getItem('avatar'));
    const first_name = currentLoginData?.data?.userDetails?.first_name;
    const user_img = currentLoginData?.data?.userDetails?.photo;
    const role_name = currentLoginData?.data?.userDetails?.role?.profile_name;
    const role_id = currentLoginData?.data?.userDetails?.id;
    const profile_name = profileData?.name;
    const profile_pic = profileData?.photo;
    const school_name = currentLoginData?.data?.userDetails?.school?.school_name || 'Smart Kindergarten';

    useEffect(() => {
        dispatch(getLatestMail());
    }, []);

    const logoutHandler = (e) => {
        e.preventDefault();
        sessionStorage.removeItem('login-Data');
        sessionStorage.removeItem('avatar');
        sessionStorage.removeItem('currentTab');
        window.location.href = '/';
    };

    const toggleMenu = (e) => {
        e.preventDefault();
        // if (document.body.classList.contains('menu-open')) {
        //     dispatch({ type: actionType.TOGGLE_SIDEBAR });
        //     document.body.classList.remove('menu-open');
        // } else {
        //     dispatch({ type: actionType.TOGGLE_SIDEBAR });
        //     document.body.classList.add('menu-open');
        // }
        // if (document.body.getAttribute('data-leftbar-compact-mode') === 'condensed') {
        //     document.body.removeAttribute('data-leftbar-compact-mode');
        // } else {
        //     document.body.setAttribute('data-leftbar-compact-mode', 'condensed');
        // }
        if (window.innerWidth < 768 && isExpanded === false) {
            dispatch({ type: actionType.TOGGLE_SIDEBAR_ON });
            document.body.classList.add('menu-open');
            document.body.removeAttribute('data-leftbar-compact-mode');
            return;
        }
        if (
            document.body.classList.contains('menu-open') ||
            document.body.getAttribute('data-leftbar-compact-mode') !== 'condensed'
        ) {
            dispatch({ type: actionType.TOGGLE_SIDEBAR_OFF });
            document.body.classList.remove('menu-open');
            document.body.setAttribute('data-leftbar-compact-mode', 'condensed');
        } else {
            dispatch({ type: actionType.TOGGLE_SIDEBAR_ON });
            document.body.classList.add('menu-open');
            document.body.removeAttribute('data-leftbar-compact-mode');
        }
    };
    let profileRedirection = '';
    switch (role_name) {
        case 'TEACHER':
            profileRedirection = `/teacher/view/${role_id}/basic-info`;
            break;
        case 'STUDENT':
            profileRedirection = `/student/view/${role_id}/basic-info`;
            break;
        default:
            profileRedirection = '/settings';
            break;
    }
    let pageTile = '';
    const path = pathname.split('/')[1];
    switch (path) {
        case 'access':
            pageTile = { title: 'Access Management', image: '/images/title/title-general.png' };
            break;
        case 'school':
            pageTile = { title: 'School Management', image: '/images/title/title-school.png' };
            break;
        case 'teacher':
            pageTile = {
                title: role_name === 'TEACHER' ? 'Profile' : 'Teacher Management',
                image: '/images/title/title-teacher.png',
            };
            break;
        case 'student':
            pageTile = {
                title: role_name === 'STUDENT' ? 'Profile' : 'Student Management',
                image: '/images/title/title-student.png',
            };
            break;
        case 'users':
            pageTile = { title: 'User Management', image: '/images/title/title-meeting.png' };
            break;
        case 'settings':
            pageTile = { title: 'Profile Settings', image: '/images/title/title-assessment.png' };
            break;
        case 'resource':
            pageTile = { title: 'Lesson Management', image: '/images/title/title-meeting.png' };
            break;
        // Added be Anuja (to be changed once dynamic)
        case 'lesson-planner':
            pageTile = { title: 'Lesson Planner', image: '/images/title/title-meeting.png' };
            break;
        case 'assign-class':
            pageTile = { title: 'Assign Class', image: '/images/title/title-assessment.png' };
            break;
        case 'course-planer':
            pageTile = { title: 'Course Planer', image: '/images/title/title-student.png' };
            break;
        case 'assessment':
            pageTile = { title: 'Assessment', image: '/images/title/title-teacher.png' };
            break;
        case 'communicate':
            pageTile = { title: 'Communicate', image: '/images/title/title-assessment.png' };
            break;
        case 'timetable':
            pageTile = { title: 'Time Table', image: '/images/title/title-general.png' };
            break;
        case 'meeting':
            pageTile = { title: 'Meeting', image: '/images/title/title-school.png' };
            break;
        case ', report':
            pageTile = { title: 'Interactive', image: '/images/title/title-school.png' };
            break;
        case 'work-sheet':
            pageTile = { title: 'Practice Resource', image: '/images/title/title-school.png' };
            break;
        case 'leaves':
            pageTile = { title: 'Holidays', image: '/images/title/title-general.png' };
            break;
        default:
            pageTile = { title: role_name === 'STUDENT' ? 'Overview' : 'Dashboard', image: '/images/title.png' };
            break;
    }

    return (
        <header className="navbar-custom">
            {(interactive || report) && (
                <a href="#" className="brand">
                    <i className="bi bi-building p-1" />
                    {/* <img src="/images/brand-large.png" width="180" alt="" className="img-fluid" /> */}
                    <span className="school_header_color">{school_name}</span>
                </a>
            )}
            <button type="button" className="btn toggle-menu-mobile" onClick={toggleMenu}>
                <img src="/images/hamburger-menu.svg" alt="Menu toggle" width="30" />
            </button>
            <div className="row">
                <div className="col text-start title-section">
                    {report ? (
                        <>
                            <div className="title-avatar">
                                <img src="/images/title/title-school.png" alt="" />
                            </div>
                            <div className="title-content">
                                <h1 className="page-title">Reports</h1>
                            </div>
                        </>
                    ) : !interactive ? (
                        <>
                            <div className="title-avatar">
                                <img src={pageTile.image} alt="" />
                            </div>
                            <div className="title-content">
                                <h1 className="page-title">{pageTile.title}</h1>
                                <BreadCrumb />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="title-avatar">
                                <img src="/images/title/title-school.png" alt="" />
                            </div>
                            <div className="title-content">
                                <h1 className="page-title">Activities</h1>
                            </div>
                        </>
                    )}
                </div>
                <div className="col text-end topbar-menu-section">
                    <ul className="list-unstyled topbar-menu">
                        {/* {['teacher', 'student', 'users', 'assessment', 'assign-class', 'course-planer'].includes(
                            path
                        ) && (
                            <li>
                                <button
                                    type="button"
                                    className="nav-link nav-settings"
                                    onClick={() => {
                                        dispatch({ type: actionType.TOGGLE_FILTER });
                                    }}
                                >
                                    <img src="/images/icons/filter.svg" alt="Settings" />
                                </button>
                            </li>
                        )} */}
                        {!report && !hideinfo && (
                            <li>
                                <Link
                                    to="/communicate/message"
                                    className="fa-stack fa-1x has-badge"
                                    data-count={getLatestMailResponse?.meta?.pagination?.totalRecordCount || 0}
                                >
                                    <i className="fa fa-circle fa-stack-2x" />
                                    <i className="fa fa-bell fa-stack-1x fa-inverse" />
                                </Link>
                            </li>
                        )}
                        {!report && !hideinfo && (
                            <li className="dropdown account-list">
                                <Link
                                    className="nav-link dropdown-toggle nav-user arrow-none me-0 custom-nav-user"
                                    id="dropdownProfile"
                                    data-bs-toggle="dropdown"
                                    to="/"
                                    role="button"
                                    aria-haspopup="false"
                                    aria-expanded="false"
                                >
                                    <span className="account-user-avatar">
                                        <img
                                            src={profile_pic || user_img || '/images/avatar-temp.png'}
                                            alt="user"
                                            className="rounded-circle"
                                        />
                                    </span>
                                    <span className="d-inline-flex ">
                                        <div className="d-flex flex-column justify-content-start align-items-start">
                                            <span className="account-user-name">{` ${
                                                profile_name || first_name
                                            }`}</span>
                                            <small className="small-profile-custom">{role_name}</small>
                                        </div>
                                    </span>
                                </Link>
                                <div
                                    className="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown"
                                    aria-labelledby="dropdownProfile"
                                >
                                    <Link to={profileRedirection} className="dropdown-item profile-item">
                                        <span>Profile</span>
                                    </Link>

                                    <div className="dropdown-item profile-item" onClick={logoutHandler}>
                                        <span>Logout</span>
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
            {/* {role_name === 'STUDENT' && <AnimatedCharacters />} */}
            {role_name === 'STUDENT' && !interactive && <AnimatedObjects />}
        </header>
    );
};

export default Header;
