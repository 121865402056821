import apiGateWay from '../../../../serviceHandlers';
import { lessonPlannerEndPoints } from '../../../../config/serviceEndPoints';
// *Syllabus
export const getSyllabuList = () => {
    return apiGateWay.get(`${lessonPlannerEndPoints.syllabus}/list`);
};
export const getLessonPlannerSyllabuses = (params) => {
    return apiGateWay.get(`${lessonPlannerEndPoints.syllabus}`, { params });
};
export const createLessonPlannerSyllabuses = (params, paths, payload) =>
    apiGateWay.post(lessonPlannerEndPoints.syllabus, payload);

export const editLessonPlannerSyllabuses = (params, paths, payload) =>
    apiGateWay.put(`${lessonPlannerEndPoints.syllabus}/${paths.syllabus_id}`, payload);

export const deleteLessonPlannerSyllabuses = ({ syllabus_id }) => {
    return apiGateWay.delete(`${lessonPlannerEndPoints.syllabus}/${syllabus_id}`);
};
