import * as commonTypes from '../../../common/constants';
import * as service from './services';
import { request } from '../../../../utils/utils';

// *Lesson Plan action-types
const GET_LESSON_PLAN_LIST_REQUEST = 'GET_LESSON_PLAN_LIST_REQUEST';
const GET_LESSON_PLAN_LIST_SUCCESS = 'GET_LESSON_PLAN_LIST_SUCCESS';
const GET_LESSON_PLAN_LIST_FAILURE = 'GET_LESSON_PLAN_LIST_FAILURE';

const DOWNLOAD_LESSON_PLAN_REQUEST = 'DOWNLOAD_LESSON_PLAN_REQUEST';
const DOWNLOAD_LESSON_PLAN_SUCCESS = 'DOWNLOAD_LESSON_PLAN_SUCCESS';
const DOWNLOAD_LESSON_PLAN_FAILURE = 'DOWNLOAD_LESSON_PLAN_FAILURE';
const DOWNLOAD_LESSON_PLAN_RESET = 'DOWNLOAD_LESSON_PLAN_RESET';

// *Lesson Plan Report actions
export const getLessonPlanReportList = (params) => async (dispatch) => {
    request({
        dispatch,
        api: service.getLessonPlanReportList,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: GET_LESSON_PLAN_LIST_REQUEST }],
        successActions: [
            { type: GET_LESSON_PLAN_LIST_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_LESSON_PLAN_LIST_REQUEST },
        ],
        failureActions: [
            { type: GET_LESSON_PLAN_LIST_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_LESSON_PLAN_LIST_REQUEST },
        ],
    });
};

export const downloadLessonPlanReportList = (params) => async (dispatch) =>
    request({
        dispatch,
        api: service.downloadLessonPlanReport,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: DOWNLOAD_LESSON_PLAN_REQUEST }],
        successActions: [
            { type: DOWNLOAD_LESSON_PLAN_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: DOWNLOAD_LESSON_PLAN_REQUEST },
            { type: DOWNLOAD_LESSON_PLAN_RESET, payload: null },
        ],
        failureActions: [
            { type: DOWNLOAD_LESSON_PLAN_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: DOWNLOAD_LESSON_PLAN_REQUEST },
        ],
    });

// *Lesson Plan Report reducer
const initialState = {
    getLessonPlanReportResponse: {},
    downloadLessonPlanReportResponse: {},
};

const SchoollessonPlanReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LESSON_PLAN_LIST_SUCCESS:
            return {
                ...state,
                getLessonPlanReportResponse: { success: true, ...action.payload?.data },
            };
        case GET_LESSON_PLAN_LIST_FAILURE:
            return {
                ...state,
                getLessonPlanReportResponse: { success: false, ...action.payload?.data },
            };
        case DOWNLOAD_LESSON_PLAN_SUCCESS:
            return {
                ...state,
                downloadLessonPlanReportResponse: {
                    success: true,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case DOWNLOAD_LESSON_PLAN_FAILURE:
            return {
                ...state,
                downloadLessonPlanReportResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case DOWNLOAD_LESSON_PLAN_RESET:
            return {
                ...state,
                downloadLessonPlanReportResponse: {},
            };
        default:
            return state;
    }
};

export default SchoollessonPlanReportReducer;
