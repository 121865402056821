import * as commonTypes from '../../../common/constants';
import * as service from './services';
import { request } from '../../../../utils/utils';

// *Books action-types
const GET_SEPECIFIC_BOOKS_REQUEST = 'GET_SEPECIFIC_BOOKS_REQUEST';
const GET_SEPECIFIC_BOOKS_SUCCESS = 'GET_SEPECIFIC_BOOKS_SUCCESS';
const GET_SEPECIFIC_BOOKS_FAILURE = 'GET_SEPECIFIC_BOOKS_FAILURE';

const GET_SEPECIFIC_SCHOOL_BOOKS_REQUEST = 'GET_SEPECIFIC_SCHOOL_BOOKS_REQUEST';
const GET_SEPECIFIC_SCHOOL_BOOKS_SUCCESS = 'GET_SEPECIFIC_SCHOOL_BOOKS_SUCCESS';
const GET_SEPECIFIC_SCHOOL_BOOKS_FAILURE = 'GET_SEPECIFIC_SCHOOL_BOOKS_FAILURE';

const GET_BOOKS_REQUEST = 'GET_BOOKS_REQUEST';
const GET_BOOKS_SUCCESS = 'GET_BOOKS_SUCCESS';
const GET_BOOKS_FAILURE = 'GET_BOOKS_FAILURE';

const POST_BOOKS_REQUEST = 'POST_BOOKS_REQUEST';
const POST_BOOKS_SUCCESS = 'POST_BOOKS_SUCCESS';
const POST_BOOKS_FAILURE = 'POST_BOOKS_FAILURE';
const POST_BOOKS_RESET = 'POST_BOOKS_RESET';

const EDIT_BOOKS_REQUEST = 'EDIT_BOOKS_REQUEST';
const EDIT_BOOKS_SUCCESS = 'EDIT_BOOKS_SUCCESS';
const EDIT_BOOKS_FAILURE = 'EDIT_BOOKS_FAILURE';
const EDIT_BOOKS_RESET = 'EDIT_BOOKS_RESET';

const DELETE_BOOKS_REQUEST = 'DELETE_BOOKS_REQUEST';
const DELETE_BOOKS_SUCCESS = 'DELETE_BOOKS_SUCCESS';
const DELETE_BOOKS_FAILURE = 'DELETE_BOOKS_FAILURE';

// *Books actions
export const getSpecificBooks = (params) => async (dispatch) => {
    request({
        dispatch,
        api: service.getSpecificLessonPlannerBooks,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: GET_SEPECIFIC_BOOKS_REQUEST }],
        successActions: [
            { type: GET_SEPECIFIC_BOOKS_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_SEPECIFIC_BOOKS_REQUEST },
        ],
        failureActions: [
            { type: GET_SEPECIFIC_BOOKS_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_SEPECIFIC_BOOKS_REQUEST },
        ],
    });
};

export const getSpecificBooksBySchool = (params) => async (dispatch) => {
    request({
        dispatch,
        api: service.getSpecificLessonPlannerBooksBySchool,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: GET_SEPECIFIC_SCHOOL_BOOKS_REQUEST }],
        successActions: [
            { type: GET_SEPECIFIC_SCHOOL_BOOKS_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_SEPECIFIC_SCHOOL_BOOKS_REQUEST },
        ],
        failureActions: [
            { type: GET_SEPECIFIC_SCHOOL_BOOKS_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_SEPECIFIC_SCHOOL_BOOKS_REQUEST },
        ],
    });
};

export const getBooks = (params) => async (dispatch) => {
    request({
        dispatch,
        api: service.getLessonPlannerBooks,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: GET_BOOKS_REQUEST }],
        successActions: [
            { type: GET_BOOKS_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_BOOKS_REQUEST },
        ],
        failureActions: [
            { type: GET_BOOKS_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_BOOKS_REQUEST },
        ],
    });
};

export const createBook = (payload) => async (dispatch) =>
    request({
        dispatch,
        api: service.createLessonPlannerBooks,
        payload,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: POST_BOOKS_REQUEST }],
        successActions: [
            { type: POST_BOOKS_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: POST_BOOKS_REQUEST },
            { type: POST_BOOKS_RESET, payload: null },
        ],
        failureActions: [
            { type: POST_BOOKS_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: POST_BOOKS_REQUEST },
            { type: POST_BOOKS_RESET, payload: null },
        ],
    });

export const editBook = (paths, payload) => async (dispatch) => {
    return request({
        dispatch,
        api: service.editLessonPlannerBooks,
        payload,
        paths,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: EDIT_BOOKS_REQUEST }],
        successActions: [
            { type: EDIT_BOOKS_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: EDIT_BOOKS_REQUEST },
            { type: EDIT_BOOKS_RESET, payload: null },
        ],
        failureActions: [
            { type: EDIT_BOOKS_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: EDIT_BOOKS_REQUEST },
        ],
    });
};

export const deleteBook = (params) => async (dispatch) => {
    request({
        dispatch,
        api: service.deleteLessonPlannerBooks,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: DELETE_BOOKS_REQUEST }],
        successActions: [
            { type: DELETE_BOOKS_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: DELETE_BOOKS_REQUEST },
        ],
        failureActions: [
            { type: DELETE_BOOKS_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: DELETE_BOOKS_REQUEST },
        ],
    });
};

// *Books reducer
const initialState = {
    getSepecificBooksRes: {},
    getSpecificSchoolBooksRes: {},
    getBooksResponse: {},
    createBookResponse: {},
    deleteBookResponse: {},
    editBookResponse: {},
};

const lessonPlannerBooksReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SEPECIFIC_BOOKS_SUCCESS:
            return {
                ...state,
                getSepecificBooksRes: { success: true, ...action.payload?.data },
            };
        case GET_SEPECIFIC_BOOKS_FAILURE:
            return {
                ...state,
                getSepecificBooksRes: { success: false, ...action.payload?.data },
            };
        case GET_SEPECIFIC_SCHOOL_BOOKS_SUCCESS:
            return {
                ...state,
                getSpecificSchoolBooksRes: { success: true, ...action.payload?.data },
            };
        case GET_SEPECIFIC_SCHOOL_BOOKS_FAILURE:
            return {
                ...state,
                getSpecificSchoolBooksRes: { success: false, ...action.payload?.data },
            };
        case GET_BOOKS_SUCCESS:
            return {
                ...state,
                getBooksResponse: { success: true, ...action.payload?.data },
            };
        case GET_BOOKS_FAILURE:
            return {
                ...state,
                getBooksResponse: { success: false, ...action.payload?.data },
            };
        case POST_BOOKS_SUCCESS:
            return {
                ...state,
                createBookResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case POST_BOOKS_FAILURE:
            return {
                ...state,
                createBookResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case POST_BOOKS_RESET:
            return {
                ...state,
                createBookResponse: {},
            };
        case EDIT_BOOKS_SUCCESS:
            return {
                ...state,
                editBookResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case EDIT_BOOKS_FAILURE:
            return {
                ...state,
                editBookResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case DELETE_BOOKS_SUCCESS:
            return {
                ...state,
                deleteBookResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case DELETE_BOOKS_FAILURE:
            return {
                ...state,
                deleteBookResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        default:
            return state;
    }
};

export default lessonPlannerBooksReducer;
